import { SET_TAGS, SET_TAG_MATCHINGS } from '../types';

const reducer = (state, action) => {
  switch(action.type) {
    case SET_TAGS:
      return {
        ...state,
        tags: action.payload,
        tagsFetched: true
      }
    case SET_TAG_MATCHINGS:
      return {
        ...state,
        tagMatchings: action.payload,
        tagMatchingsFetched: true
      }
    default:
      return state;
  }
}

export default reducer;