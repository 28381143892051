import { useState, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { ClickAwayListener } from '@material-ui/core'

import {
  Eye,
  Download,
  GripLines,
  Share,
  Pen,
  History,
  FileAlt,
  CloudUpload,
  Sync,
  FileDownload,
  Delete,
  Duplicate,
  EllipsisV,
  Signature,
  RegisteredMail,
  Plus,
  EditNote
} from '../../assets/icons'
import {
  SingleDocumentVariables,
  SingleDocumentShare,
  SingleDocumentSign,
  SingleDocumentHistory,
  SingleDocumentSynthesis,
  SingleDocumentInject,
  SingleDocumentAttachments,
} from '.'
import { CustomTooltip, Alert } from '../ui_new'
import { useSingleDocumentActions } from '../../hooks'
import { UserContext, LoaderContext, RegisteredMailContext, DocumentsContext } from '../../context'
import useWindowDimensions from '../../hooks/useWindowDimensions'
import { isMobile } from 'react-device-detect'
import SingleDocumentSignVialink from './SingleDocumentSignVialink'
import { CheckCircle, ContentCopy, DeleteOutline, PaletteOutlined, Redo, Save, Undo } from '@mui/icons-material'
import { FEATURE, isFeatureEnabled } from '../../utils'
import SingleDocumentRenderSettings from './SingleDocumentRenderSettings'

const StandardTemplateMain = ({
  singleDoc,
  templateData,
  documentValues,
  onValuesChange,
  documentCheckboxValues,
  onCheckboxValueChange,
  onCustomCoverChange,
  useCustomCover,
  onAgencyModalOpen,
  onNotariesModalOpen,
  linesAdded,
  onSave,
  onDocumentPreview,
  onAddLines,
  attachments,
  onAttachmentShareUpdate,
  onAttachmentShareSave,
  documentId,
  documentName,
  template,
  docContacts,
  docSignatureRecipients,
  onSetDocumentValues,
  docHistory,
  onVersionRestore,
  onResetSetup,
  view,
  onSetView,
  onSetDocumentAttachments,
  onSetTab,
  onToggleSidePanel,
  isSidePanelOpen,
  previewSettings,
  templateMetaSections,
  isContentEditable,
  onToggleContentEditable,
  onSectionContentChanged,
  onRenderSettingsSave,
  onPreviewRefresh,
  onCreateDocument
}) => {
  const { downloadDocument, moveDocumentToDeleteDocuments, downloadDocumentData, immocloudUploadAvailable, uploadToCloud, getPreviewData } = useSingleDocumentActions()
  const { immocloudConfig, hideActionBar, agency, siteConfigs, user, partner } = useContext(UserContext)
  const { setShowGlobalResponseLoader, setLoading, setGlobalResponseLoaderText } = useContext(LoaderContext)
  const { createStandardTemplate, deleteStandardTemplate, duplicateStandardTemplate } = useContext(DocumentsContext)
  const [showShareModal, setShowShareModal] = useState(false)
  const [showSignModal, setShowSignModal] = useState(false)
  const [showHistoryModal, setShowHistoryModal] = useState(false)
  const [showSynthesisModal, setShowSynthesisModal] = useState(false)
  const [showInjectModal, setShowInjectModal] = useState(false)
  const [showDeleteAlert, setShowDeleteAlert] = useState(false)
  const [showDocxDownloadAlert, setShowDocxDownloadAlert] = useState(false)
  const [showMoreActions, setShowMoreActions] = useState(false)
  const [showRenderSettingsModal, setShowRenderSettingsModal] = useState(false)
  const history = useHistory()

  // On document save
  const handleSave = () => {
    onSave()
  }

  // On document preview
  const handleDocumentPreview = () => {
    if (!onDocumentPreview) return
    onDocumentPreview()
  }

  const agencyFontConfig = () => {
    if (!agency) {
      return {}
    }
    const font_config = {
      heading_2: {
        color: agency.cover_color,
        borderColor: agency.cover_color,
      },
      heading_3: {
        color: "#ffffff",
        backgroundColor: agency.cover_color,
      },
      footer_title: {
        color: agency.cover_color,
      },
      cover_title: {
        color: agency.cover_color,
      }
    }
    return font_config
  }

  const agencyConfig = () => {
    if (!agency) {
      return {}
    }
    const config = {
      cover_color: agency.cover_color,
    }
    return config
  }

  // On pdf download
  const handlePdfDownload = async () => {
    setLoading(true)
    await downloadDocument('pdf', {
      ...singleDoc,
      custom_cover: useCustomCover,
    }, { font_config: { font_size: previewSettings.size, ...agencyFontConfig() }, use_watermark: previewSettings.watermark, ...agencyConfig() })
    setLoading(false)
  }

  // On open docx download alert
  const handleOpenDocxDownloadAlert = () => {
    setShowDocxDownloadAlert(true)
  }

  // On close docx download alert
  const handleCloseDocxDownloadAlert = () => {
    setShowDocxDownloadAlert(false)
  }

  // // On docx download
  const handleDocxDownload = async () => {
    setLoading(true)
    await downloadDocument('docx', {
      ...singleDoc,
      custom_cover: useCustomCover,
    }, { font_config: { font_size: previewSettings.size, ...agencyFontConfig() }, use_watermark: previewSettings.watermark, ...agencyConfig() })
    setShowDocxDownloadAlert(false)
    setLoading(false)
  }


  // On add lines
  const handleAddLines = () => {
    onAddLines()
  }

  // On open share modal
  const handleOpenShareModal = () => {
    setShowShareModal(true)
  }

  // On close share modal
  const handleCloseShareModal = () => {
    setShowShareModal(false)
  }

  // On open sign modal
  const handleOpenSignModal = () => {
    setShowSignModal(true)
  }

  // On close sign modal
  const handleCloseSignModal = () => {
    setShowSignModal(false)
  }

  // On open history modal
  const handleOpenHistoryModal = async () => {
    setShowHistoryModal(true)
  }

  // On close history modal
  const handleCloseHistoryModal = () => {
    setShowHistoryModal(false)
  }

  // On open synthesis modal
  const handleOpenSynthesisModal = async () => {
    setShowSynthesisModal(true)
  }

  // On close synthesis modal
  const handleCloseSynthesisModal = () => {
    setShowSynthesisModal(false)
  }

  // On open inject modal
  const handleOpenInjectModal = () => {
    setShowInjectModal(true)
  }

  // On close inject modal
  const handleCloseInjectModal = () => {
    setShowInjectModal(false)
  }

  // On open delete alert
  const handleOpenDeleteAlert = () => {
    setShowDeleteAlert(true)
  }

  // On close delete alert
  const handleCloseDeleteAlert = () => {
    setShowDeleteAlert(false)
  }

  // On delete document
  const handleDeleteDocument = async () => {
    setShowGlobalResponseLoader(true)
    try {
      await deleteStandardTemplate(documentId)
      // await moveDocumentToDeleteDocuments({
      //   ...singleDoc,
      //   id: documentId,
      // })
      history.push('/standard-templates')
    } catch (err) {
    } finally {
      setShowGlobalResponseLoader(false)
    }
  }

  // On data download
  const handleDownloadData = () => {
    downloadDocumentData({
      documentValues,
      documentCheckboxValues,
      documentName,
      documentId,
    })
  }

  // On upload to upload
  const handleCloudUpload = async () => {
    setShowGlobalResponseLoader(true)
    try {
      await uploadToCloud({
        template,
        doc: singleDoc,
        immocloudConfig,
      })
    } catch (err) {
      console.log(err)
    } finally {
      setShowGlobalResponseLoader(false)
    }
  }

  // On more actions click away
  const handleMoreActionsClickAway = () => {
    setShowMoreActions(false)
  }

  // On toggle more actions
  const handleToggleMoreActions = () => {
    setShowMoreActions(!showMoreActions)
  }

  const handleDuplicateStandardTemplate = async () => {
    const standardTemplateResponse = await duplicateStandardTemplate({ ...singleDoc, values: documentValues, checkboxValues: documentCheckboxValues, name: `${documentName} copie`, custom_cover: useCustomCover, attachments: attachments })
    if (standardTemplateResponse.error) {
      return
    }
    history.push(`/standard-templates/${standardTemplateResponse.id}`)
  }

  const handleDeleteStandardTemplate = async () => {
    setShowDeleteAlert(true)
  }

  const handleUndo = () => {
      document.execCommand('undo', false, null)
  }

  const handleRedo = () => {
      document.execCommand('redo', false, null)
  }

  return (
    <div>
      <div
        className="document-main__actions"
        style={{
          display: isMobile ? (hideActionBar ? 'none' : '') : '',
        }}
      >
        <button
            className="button height-40 button--outline-primary button--with-icon btn-text button--hover-light-pink mr-2"
            onClick={() => setShowRenderSettingsModal(true)}
        >
            <PaletteOutlined fontSize="small" /><span className=''> Mise en page</span>
        </button>
        <CustomTooltip content="Enregistrer le modèle type">
          <button
            className="icon-btn icon-btn--md"
            onClick={handleSave}
          >
            <Save fontSize="small" />
          </button>
        </CustomTooltip>
        <CustomTooltip content="Dupliquer le modèle type">
          <button
            className="icon-btn icon-btn--md"
            onClick={handleDuplicateStandardTemplate}
          >
            <ContentCopy fontSize="small" />
          </button>
        </CustomTooltip>
        <CustomTooltip content="Supprimer le modèle type">
          <button
            className="icon-btn icon-btn--md"
            onClick={handleDeleteStandardTemplate}
          >
            <DeleteOutline fontSize="small" />
          </button>
        </CustomTooltip>
        {isContentEditable && <div className="document-main__editor-toolbar mr-2">
          <CustomTooltip content="Annuler">
              <div className=''>
                  <button
                      className="icon-btn icon-btn--sm"
                      onClick={handleUndo}
                  >
                      <Undo fontSize='small' />
                  </button>
              </div>
          </CustomTooltip>
          <CustomTooltip content="Rétablir">
              <button
                  className="icon-btn icon-btn--sm"
                  onClick={handleRedo}
              >
                  <Redo fontSize='small' />
              </button>
          </CustomTooltip>
        </div>}
        {isFeatureEnabled(FEATURE.EDITABLE_CONTENT_LITE, siteConfigs, partner, user) && <button
            className={`button rounded-sm btn-text button--with-icon ${isContentEditable ? 'button--outline-success' : 'button--outline-danger'}`}
            onClick={onToggleContentEditable}
        >
            <EditNote /><span className=''>{isContentEditable ? 'Edition activée' : 'Edition désactivée'}</span>
        </button>}

        <button
          className="button action-btn ml-auto"
          onClick={onCreateDocument}
        >
          <span>
            <CheckCircle />
            Créer un document
          </span>
        </button>
      </div>
      <div className="document-main__body">
        {view === 'variables' && (
          <SingleDocumentVariables
            templateData={templateData}
            documentValues={documentValues}
            onValuesChange={onValuesChange}
            documentCheckboxValues={documentCheckboxValues}
            onCheckboxValueChange={onCheckboxValueChange}
            onCustomCoverChange={onCustomCoverChange}
            useCustomCover={useCustomCover}
            onAgencyModalOpen={onAgencyModalOpen}
            onNotariesModalOpen={onNotariesModalOpen}
            linesAdded={linesAdded}
            templateMetaSections={templateMetaSections}
            isContentEditable={isContentEditable}
            onSectionContentChanged={onSectionContentChanged}
            docId={documentId}
          />
        )}
        {view === 'attachments' && (
          <SingleDocumentAttachments
            attachments={attachments}
            docId={documentId}
            onSetDocumentAttachments={onSetDocumentAttachments}
            onSetView={onSetView}
            onSetTab={onSetTab}
          />
        )}
      </div>

      {showShareModal && (
        <SingleDocumentShare
          onClose={handleCloseShareModal}
          attachments={attachments}
          onAttachmentShareUpdate={onAttachmentShareUpdate}
          onAttachmentShareSave={onAttachmentShareSave}
          documentName={documentName}
          documentId={documentId}
          singleDoc={singleDoc}
          template={template}
          documentValues={documentValues}
          useCustomCover={useCustomCover}
        />
      )}

      {showSignModal && (
        <SingleDocumentSignVialink
          onClose={handleCloseSignModal}
          documentName={documentName}
          docId={documentId}
          docAttachments={attachments}
          docSignatureRecipients={docSignatureRecipients}
          singleDoc={{
            ...singleDoc,
            name: documentName,
            values: documentValues,
            custom_cover: useCustomCover,
            id: documentId,
          }}
          docContacts={docContacts}
          template={template}
        />
      )}

      {showHistoryModal && (
        <SingleDocumentHistory
          onClose={handleCloseHistoryModal}
          docHistory={docHistory}
          onRestore={onVersionRestore}
        />
      )}

      {showSynthesisModal && (
        <SingleDocumentSynthesis
          onClose={handleCloseSynthesisModal}
          documentValues={documentValues}
          onSetDocumentValues={onSetDocumentValues}
          data={template}
        />
      )}

      {showInjectModal && (
        <SingleDocumentInject
          onClose={handleCloseInjectModal}
          template={template}
          doc={{
            ...singleDoc,
            name: documentName,
            values: documentValues,
            custom_cover: useCustomCover,
            id: documentId,
          }}
          documentValues={documentValues}
        />
      )}

      {showDeleteAlert && (
        <Alert
          onClose={handleCloseDeleteAlert}
          text="Êtes-vous sûr de vouloir supprimer ce modèle ?"
          onSubmit={handleDeleteDocument}
          deleteAlert={true}
        />
      )}

      {showDocxDownloadAlert && (
        <Alert
          onClose={handleCloseDocxDownloadAlert}
          onOkClose={handleDocxDownload}
          text="Note importante"
          bodyText="Immo Docs ne pourra être tenu pour responsable des modifications apportées au .docx"
          showOk
          okBtnText="Télécharger le .docx"
        />
      )}
      {showRenderSettingsModal && (
        <SingleDocumentRenderSettings
            onClose={() => setShowRenderSettingsModal(false)}
            previewSettings={previewSettings}
            onPreviewRefresh={onPreviewRefresh}
            linesAdded={linesAdded}
            useCustomCover={useCustomCover}
            templateData={templateData}
            onSave={onRenderSettingsSave}
        />
      )}
    </div>
  )
}

export default StandardTemplateMain
