import { useContext, useEffect, useState } from 'react'
import { Sort } from '../../assets/icons'
import { TemplatesTableRow, TemplatesFoldersTableRow, StandardTemplatesTableRow } from '../ui_new'
import { AdminFeatureWrapper } from '../utils/AdminFeatureWrapper'
import { FolderContext } from '../../context'

const TemplatesTable = ({ filteredTemplates, onSort, activeSort, activeOrder, filteredFolders, onSelectFolder, onPreview, onCreateDocument, onTemplateDelete, onTemplateEdit, onTemplateToggleHidden, currentlyActiveFolder, onInfo, onHistory, rootFolders, view = 'templates', onManageAttachments }) => {
  const { foldersOrderMap } = useContext(FolderContext)
  const [orderedFolders, setOrderedFolders] = useState([]);

  useEffect(() => {
    const viewEdited = view === 'standard-templates' ? 'standard_templates' : view;
    const rootOrder = foldersOrderMap?.[viewEdited]?.[currentlyActiveFolder?.id] || [];
    let orderedFoldersCopy = rootOrder.map(id => filteredFolders.find(folder => folder.id === id));
    // filter out undefined folders & add folders that are not in the order
    filteredFolders.forEach(folder => {
      if (!orderedFoldersCopy.includes(folder)) {
        orderedFoldersCopy.push(folder);
      }
    });
    orderedFoldersCopy = orderedFoldersCopy.filter(folder => folder);
    setOrderedFolders(orderedFoldersCopy);
  }, [filteredFolders, foldersOrderMap, currentlyActiveFolder, view]);
  

  return (
    <div className="documents-table-parent">
      <div className="documents-table">
        <div className="documents-table__head">
          <div className="documents-table-row">
            <div className="documents-table-column documents-table-column__name" style={{ width: '100%', maxWidth: '81%' }}>
              <p className={`${activeSort === 'name' ? 'active' : ''}`}>Nom</p>
            </div>
            {/* <div className="documents-table-column">
          </div> */}
            {view === 'templates' && <AdminFeatureWrapper rule="any_admin">
              <div className="documents-table-column documents-table-column__status" style={{ width: '100%', maxWidth: '8.6%' }}>
                <p>Statut</p>
              </div>
            </AdminFeatureWrapper>}
            <div className="documents-table-column documents-table-column__date" style={{ width: '100%', maxWidth: '8.6%' }}>
              <p className={`${activeSort === 'meta.created' ? 'active' : ''}`}>Date de <br />création</p>
            </div>
            <div className="documents-table-column documents-table-column__date" style={{ width: '100%', maxWidth: '8.6%' }}>
              <p className={`${activeSort === 'meta.updated' ? 'active' : ''}`}>Date de modification</p>
            </div>
            {view === 'templates' && <div className="documents-table-column documents-table-column__history" style={{ width: '100%', maxWidth: '3.3%' }}>
            </div>}
          </div>
        </div>
        <div className="documents-table__body">
          {/* {((!currentlyActiveFolder || currentlyActiveFolder === 'root') ? rootFolders : currentlyActiveFolder?.subfolders)?.map((folder, idx) => { */}
          {orderedFolders?.map((folder, idx) => {
            return (
              <TemplatesFoldersTableRow
                key={idx}
                id={folder.id}
                folder={folder}
                onSelectFolder={onSelectFolder}
                view={view}
              />
            )
          })}
          {filteredTemplates?.map((template, idx) => {
            if (view === 'templates') {
              return (
                <TemplatesTableRow
                  key={idx}
                  id={template.id}
                  template={template}
                  onPreview={onPreview}
                  onInfo={onInfo}
                  onHistory={onHistory}
                  onCreateDocument={onCreateDocument}
                  onTemplateDelete={onTemplateDelete}
                  onTemplateEdit={onTemplateEdit}
                  onTemplateToggleHidden={onTemplateToggleHidden}
                  onManageAttachments={onManageAttachments}
                />
              )
            } else if (view === 'standard-templates') {
              return (
                <StandardTemplatesTableRow
                  key={idx}
                  id={template.id}
                  template={template}
                  onPreview={onPreview}
                  onInfo={onInfo}
                  onHistory={onHistory}
                  onCreateDocument={onCreateDocument}
                  onTemplateDelete={onTemplateDelete}
                  onTemplateEdit={onTemplateEdit}
                  onTemplateToggleHidden={onTemplateToggleHidden}
                />
              )
            }
            return null
          })}
        </div>
      </div>
    </div>
  )
}

export default TemplatesTable