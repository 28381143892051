import React, { useState, useEffect, cloneElement, useRef, useCallback } from 'react'
import { createPortal } from 'react-dom'
import { ClickAwayListener } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeftRounded'

const ActionsDropdown = ({ className, trigger, onTriggerClick = () => { }, headTitle, dropdownClass, withBackArrow, children, onBackButtonClick = () => { }, width = 240, hide, hideHeader = false, onClose = () => { }, hideArrow = false, maxWidth = 300 }) => {
  const [showDropdown, setShowDropdown] = useState(false)
  const [dropdownPosition, setDropdownPosition] = useState({ top: -10000, left: 0 })
  const [isReady, setIsReady] = useState(false)
  const triggerEl = useRef()
  const dropdownEl = useRef()

  // On resize
  useEffect(() => {
    window.addEventListener('resize', onResize)

    return () => {
      window.removeEventListener('resize', onResize)
    }
    // eslint-disable-next-line
  }, [])

  // Hide from outside
  useEffect(() => {
    if (hide) {
      setShowDropdown(false)
      onClose()
      setIsReady(false)
    }
  }, [hide, onClose])

  // CSS classes for dropdown
  let dropdownCssClasses = 'actions-dropdown'
  if (dropdownClass) {
    dropdownCssClasses += ` ${dropdownClass}`
  }

  // On back button click
  const backButtonClickHandler = () => {
    setShowDropdown(false)
    onBackButtonClick()
  }

  // Calculate dropdown position
  const calculateDropdownPosition = useCallback(() => {
    const { top, left, height } = triggerEl.current.getBoundingClientRect()
    const dropdownHeight = dropdownEl.current?.getBoundingClientRect().height
    let posLeft = left
    let posTop = top + height + 5 + window.scrollY

    if (left + width > window.innerWidth - 22) {
      posLeft = window.innerWidth - width - 22
    }
    if (dropdownHeight && window.innerHeight < top + height + dropdownHeight + 22) {
      posTop = window.innerHeight + window.scrollY - dropdownHeight + 40
    }
    if (dropdownHeight && window.innerHeight < dropdownHeight + 40) {
      posTop = 22
    }
    if (window.innerWidth <= width + 40) {
      posLeft = 22
    }
    if (dropdownPosition.top !== posTop || dropdownPosition.left !== posLeft) {
      setDropdownPosition({
        top: posTop,
        left: posLeft
      })
    }
    setIsReady(true)
  }, [dropdownPosition, width])

  // Set dropdown positon
  useEffect(() => {
    if (dropdownEl.current && showDropdown) {
      calculateDropdownPosition()
    }
    // eslint-disable-next-lin
  }, [showDropdown, calculateDropdownPosition])

  // Every time window resizes update dropdown position 
  const onResize = () => {
    calculateDropdownPosition()
  }

  // Render dropdown
  const renderDropdown = () => {
    if (!showDropdown || !isReady) {
      dropdownCssClasses += ' actions-dropdown--hide'
    }
    return (
      <>
        {!hideArrow && <div className="actions-dropdown__arrow" style={{ top: triggerEl.current.getBoundingClientRect().top, left: triggerEl.current.getBoundingClientRect().left }}></div>}
        <div className={dropdownCssClasses} ref={dropdownEl} style={{ top: dropdownPosition.top, left: dropdownPosition.left, width, maxWidth: maxWidth }}>
          {!hideHeader && <div className="actions-dropdown__head">
            {withBackArrow && <ChevronLeftIcon className="back" onClick={backButtonClickHandler} />}
            <p>{headTitle}</p>
            <CloseIcon onClick={() => setShowDropdown(false)} />
          </div>}
          <div className="actions-dropdown__body hide-scrollbar">
            {children}
          </div>
        </div>
      </>
    )
  }

  return (
    <ClickAwayListener onClickAway={() => { setShowDropdown(false); onClose(); setIsReady(false) }}>
      <div className={className}>
        {trigger && cloneElement(trigger, {
          ...trigger.props, ref: triggerEl, onClick: (e) => {
            e.preventDefault()
            onTriggerClick()
            setShowDropdown(!showDropdown)
          }
        })}
        {showDropdown && createPortal(renderDropdown(), document.getElementById('modal-root'))}
      </div>
    </ClickAwayListener>
  )
}

export default ActionsDropdown