export const SET_LOADING = 'SET_LOADING';
export const SET_TEMPLATES = 'SET_TEMPLATES';
export const SET_DOCUMENTS = 'SET_DOCUMENTS';
export const SET_FETCH_DOCUMENTS = 'SET_FETCH_DOCUMENTS';
export const SET_FILTERED_DOCUMENTS = 'SET_FILTERED_DOCUMENTS';
export const SET_ARCHIVED_DOCUMENTS = 'SET_ARCHIVED_DOCUMENTS';
export const SET_DELETED_DOCUMENTS = 'SET_DELETED_DOCUMENTS';
export const SET_SHOW_FILTERED_DOCS = 'SET_SHOW_FILTERED_DOCS';
export const SET_SHOW_FILTERED_TMPLTS = 'SET_SHOW_FILTERED_TMPLTS';
export const SET_CURRENT_FILTER = 'SET_CURRENT_FILTER';
export const SET_ACTIVE_FILTER_COLUMN = 'SET_ACTIVE_FILTER_COLUMN';
export const SET_DATE_FILTERS = 'SET_DATE_FILTERS';
export const SET_FILTERED_TEMPLATES = 'SET_FILTERED_TEMPLATES';
export const SET_TEMPLATES_LOADING = 'SET_TEMPLATES_LOADING';
export const SET_ACTIVE_VARS_SECTION = 'SET_ACTIVE_VARS_SECTION';
export const SET_ACTIONS = 'SET_ACTIONS';
export const SET_FETCH_ACTIONS = 'SET_FETCH_ACTIONS';
export const SET_STANDARD_TEMPLATES = 'SET_STANDARD_TEMPLATES';
export const SET_STANDARD_TEMPLATES_LOADING = 'SET_STANDARD_TEMPLATES_LOADING';
export const SET_GENERIC_TEMPLATES = 'SET_GENERIC_TEMPLATES';
export const SET_SPECIFIC_TEMPLATES = 'SET_SPECIFIC_TEMPLATES';

// Folders
export const SET_FOLDERS_ORDER = 'SET_FOLDERS_ORDER';
export const GET_FOLDERS = 'GET_FOLDERS';
export const SET_FOLDERS = 'SET_FOLDERS';
export const SET_FOLDERS_LOADING = 'SET_FOLDERS_LOADING';

export const GET_DOCUMENTS_FOLDERS = 'GET_DOCUMENTS_FOLDERS';
export const SET_DOCUMENTS_FOLDERS = 'SET_DOCUMENTS_FOLDERS';
export const SET_DOCUMENTS_FOLDERS_LOADING = 'SET_DOCUMENTS_FOLDERS_LOADING';

export const GET_STANDARD_TEMPLATES_FOLDERS = 'GET_STANDARD_TEMPLATES_FOLDERS';
export const SET_STANDARD_TEMPLATES_FOLDERS = 'SET_STANDARD_TEMPLATES_FOLDERS';
export const SET_STANDARD_TEMPLATES_FOLDERS_LOADING = 'SET_STANDARD_TEMPLATES_FOLDERS_LOADING';

// Signatures
export const GET_SIGNATURES = 'GET_SIGNATURES';
export const SET_SIGNATURES = 'SET_SIGNATURES';
export const ADD_SIGNATURE = 'ADD_SIGNATURE';
export const DELETE_SIGNATURE = 'DELETE_SIGNATURE';

// User
export const SET_USER = 'SET_USER';
export const SET_PARTNER = 'SET_PARTNER'
export const SET_CONTACTS = 'SET_CONTACTS'
export const SET_ADMINS = 'SET_ADMINS'
export const SET_VERIFIED_CUSTOMERS = 'SET_VERIFIED_CUSTOMERS'
export const SET_SITE_CONFIGS = 'SET_SITE_CONFIGS'
export const SET_SITE_CONFIGS_LOADED = 'SET_SITE_CONFIGS_LOADED'
export const SET_CAN_USE_SIGNATURES = 'SET_CAN_USE_SIGNATURES'
export const SET_IMMOCLOUD_CONFIG = 'SET_IMMOCLOUD_CONFIG'
export const SET_USER_CLAIMS = 'SET_USER_CLAIMS'
export const SET_AGENCY = 'SET_AGENCY'

// Sections
export const GET_SECTIONS = 'GET_SECTIONS'
export const SET_SECTIONS = 'SET_SECTIONS'
export const SET_SECTIONS_MATCHINGS = 'SET_SECTION_MATCHINGS'

// Signature templates
export const GET_SIGNATURE_TEMPLATES = 'GET_SIGNATURE_TEMPLATES'
export const SET_SIGNATURE_TEMPLATES = 'SET_SIGNATURE_TEMPLATES'

// Help
export const GET_HELP_ARTICLES = 'GET_HELP_ARTICLES'
export const GET_HELP_CATEGORIES = 'GET_HELP_CATEGORIES'
export const GET_FEATURES = 'GET_FEATURES'

// Email user
export const SET_SIGNIN_RESULT = 'SET_SIGNIN_RESULT'

// Registered Mail (LRE)
export const SET_AR24_MAIL_MODAL = 'SET_AR24_MAIL_MODAL'
export const SET_SELECTED_REGISTERED_MAIL_FILTER = 'SET_SELECTED_REGISTERED_MAIL_FILTER'

// AR24 Data
export const SET_AR24_SENDER = 'SET_AR24_SENDER'
export const SET_AR24_MAIL = 'SET_AR24_MAIL'
export const SET_IDENTITY_VERIFICATION_REQUESTS = 'SET_IDENTITY_VERIFICATION_REQUESTS'
export const SET_AR24_SENDER_MODAL = 'SET_AR24_SENDER_MODAL'
export const SET_AR24_SENDER_EVENTS = 'SET_AR24_SENDER_EVENTS'

// Default Attachments
export const SET_DEFAULT_ATTACHMENTS = 'SET_DEFAULT_ATTACHMENTS'

export const SET_ATTACHMENTS_ORDER = 'SET_ATTACHMENTS_ORDER'

// Template tags
export const SET_TAGS = 'SET_TAGS'
export const SET_TAG_MATCHINGS = 'SET_TAG_MATCHINGS'
