import React, { useState, forwardRef, useEffect, useContext } from 'react'
import DeleteIcon from '@material-ui/icons/Delete'

import { Alert } from '../ui_new'
import Button from '../UI/Button'
import Loader from '../UI/Loader'
import { Checkbox } from '../ui_new'
import { DocumentsContext } from '../../context/documents/documentsState'
import { TagsContext, UserContext } from '../../context'
import { NotificationContext } from '../../context/notifications/notificationState'
import { AddBox, Edit, SellOutlined } from '@mui/icons-material'
import CreateEditTagModal from './CreateEditTagModal'

const TemplateTagsPopup = forwardRef(({ onCancel, resource }, ref) => {
  const [selectedTag, setSelectedTag] = useState(null)
  const { updateTemplate } = useContext(DocumentsContext)
  const { tags, updateTag, createTag, deleteTag } = useContext(TagsContext)
  const { setNotification } = useContext(NotificationContext)
  const { partner } = useContext(UserContext)
  const [showCreateTagModal, setShowCreateTagModal] = useState(false)
  const [tagLoading, setTagLoading] = useState(false)
  const [showDeleteTagAlert, setShowDeleteTagAlert] = useState(false)
  const [deleting, setDeleting] = useState(false)
  const [checkedIds, setCheckedIds] = useState(new Set())
  const [mode, setMode] = useState('create')
  const [loading, setLoading] = useState(false)

  const submitHandler = async (e) => {
    e.preventDefault()
    setLoading(true)
    const tagsArray = Array.from(checkedIds)
    console.log("tagsArray", tagsArray)
  
    try {
      await updateTemplate({ tagIds: tagsArray }, resource.id)
      setNotification({ msg: `Tags mis à jour avec succès!`, type: 'success' })
      setLoading(false)
      onCancel()
    } catch (err) {
      console.log(err)
      setNotification({ msg: `Une erreur est survenue, merci de réessayer`, type: 'danger' })
      setLoading(false)
    }
  }

  useEffect(() => {
    console.log("resource changed", resource)
    if (resource) {
      const templateTags = resource.tagIds || []
      const checkedIds = new Set(templateTags)
      setCheckedIds(checkedIds)
    }
  }, [resource])

  const handleCheckboxChange = (tag) => {
    setCheckedIds((prevCheckedIds) => {
      const newCheckedIds = new Set(prevCheckedIds)
      if (newCheckedIds.has(tag.id)) {
        newCheckedIds.delete(tag.id)
      } else {
        newCheckedIds.add(tag.id)
      }
      return newCheckedIds
    })
  }


  const openCreateTagModalHandler = () => {
    // onCancel()
    setMode('create')
    setShowCreateTagModal(true)
  }

  // Create tag handler
  const createTagHandler = async (data) => {
    setTagLoading(true)
    console.log("create tag", data)
    // setTagLoading(false)
    // return
    await createTag(data, () => {
      setShowCreateTagModal(false)
      setNotification({ msg: `Tag "${data.name}" créé avec succès!`, type: 'success' })
      setTagLoading(false)
    }, (err) => {
      console.log(err)
      setTagLoading(false)
      setNotification({ msg: `Une erreur est survenue, merci de réessayer`, type: 'danger' })
    })
  }

  // Remove template from tag
  const editTagClickHandler = (tag) => {
    setMode('edit')
    setSelectedTag(tag)
    setShowCreateTagModal(true)
  }

  const deleteTagClickHandler = (tag) => {
    setSelectedTag(tag)
    setShowDeleteTagAlert(true)
  }

  // Delete tag
  const deleteTagHandler = () => {
    setDeleting(true)
    deleteTag(selectedTag.id, () => {
      setNotification({ msg: `Tag supprimée avec succès !`, type: 'success' })
      setDeleting(false)
      setSelectedTag(null)
      setShowDeleteTagAlert(false)
    }, (err) => {
      setDeleting(false)
      setNotification({ msg: `Une erreur est survenue, merci de réessayer`, type: 'danger' })
    })
  }

  const updateTagHandler = (data) => {
    setTagLoading(true)
    updateTag(selectedTag.id, data, () => {
      setShowCreateTagModal(false)
      setNotification({ msg: `Tag "${data.name}" modifié avec succès!`, type: 'success' })
      setSelectedTag(null)
      setTagLoading(false)
    }, (err) => {
      console.log(err)
      setTagLoading(false)
      setNotification({ msg: `Une erreur est survenue, merci de réessayer`, type: 'danger' })
    })
  }

  return (
    <div className="folders-popup" ref={ref}>
      <div className={"folders-popup__head root"}>
        <div className="main">
          <span className="icon">
            <SellOutlined />
          </span>
          <h5>Tags</h5>
        </div>
      </div>
      <div className="folders-popup__body">
        <ul className={"folders-popup__body_list inner"}>
          {tags.length === 0 && <p className="no-data">Pas de tags</p>}
          {tags.length > 0 && tags.toSorted((a,b) => {
            if (a.name.toLowerCase() < b.name.toLowerCase()) return -1
            if (a.name.toLowerCase() > b.name.toLowerCase()) return 1
            return 0
          }).toSorted((a, b) => {
            const aIsSpecific = a.owner === partner
            const bIsSpecific = b.owner === partner
            if (aIsSpecific && !bIsSpecific) return -1
            if (!aIsSpecific && bIsSpecific) return 1
            return 0
          }).map((tag, tagIndex) => (
            <li key={`tag_${tagIndex}`} className="inner">
              <div className="root u-cursor--default">
                <Checkbox className='flex-grow' label={tag.name} checked={checkedIds.has(tag.id)} onChange={() => handleCheckboxChange(tag)} />
                {tag.owner === partner && <div className='flex gap-2'>                 
                  <span className="edit-tag cursor-pointer" onClick={() => editTagClickHandler(tag)}><Edit /></span>
                  <span className="delete-template" onClick={() => deleteTagClickHandler(tag)}><DeleteIcon /></span>
                </div>}
              </div>
            </li>
          ))}
        </ul>
      </div>
      <div className="folders-popup__foot">
        <div className="new" onClick={openCreateTagModalHandler}>
          <AddBox />
        </div>
        <Button text="Annuler" onButtonClick={onCancel} outlinePrimary className="cancel-btn" />
        <Button 
          text={!loading ? "Enregistrer" : <Loader mini normalWhite />} 
          onButtonClick={submitHandler}
          primary 
        />
      </div>

      {showCreateTagModal && <CreateEditTagModal
        onClose={() => setShowCreateTagModal(false)}
        onCreate={createTagHandler}
        onEdit={updateTagHandler}
        loading={tagLoading}
        currentTag={selectedTag}
        mode={mode}
      />}
      {showDeleteTagAlert && <Alert
        onClose={() => setShowDeleteTagAlert(false)}
        text={`Êtes-vous sûr de vouloir supprimer cette tag (${selectedTag?.name}) ?`}
        onSubmit={deleteTagHandler}
        loading={deleting}
        deleteAlert
      />}
    </div>
  )
})

export default TemplateTagsPopup