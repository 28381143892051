import { useState, useEffect, useContext, useRef, useCallback } from 'react'
import { useParams, useHistory, useLocation } from 'react-router-dom'
import qs from 'qs'
import { Prompt } from 'react-router-dom';

import { MainLayout } from '../layouts'
import {
    SingleDocumentSidebar,
    SingleDocumentMain,
    SingleDocumentSidePanel,
    AgencyModal,
    NotariesModal,
    AddEditNotaryModal,
} from '../components/misc'
import { ResponseLoader, ResourceNotFound, Alert } from '../components/ui_new'
import {
    FileAlt2,
} from '../assets/icons'
import {
    DocumentsContext,
    NotificationContext,
    UserContext,
    LoaderContext,
    SingleDocumentContext,
} from '../context'
import {
    fetch_document_history,
    fetch_document_data,
    add_document_event,
    fetch_document_events,
} from '../services/firestore'
import { useSingleDocumentActions, useVariablesActions } from '../hooks'
import { FEATURE, areArraysEqual, areObjectsEqual, areSectionConditionsMet, arrayWithOrderedIds, buildContentChanges, convertToTemplateObjWithUniqueVarIndexes, defaultAttachmentsForDocument, getActiveContactsFromData, isFeatureEnabled } from '../utils'
import {
    TEXT_LINES,
    TEXTAREA_LINES,
    DATE_LINES,
    NUMBER_LINES,
    CONTACT_ID_VARIABLES,
} from '../constants'
import config from '../config.json'
import { isMobile } from 'react-device-detect'
import { AttachmentsContext } from '../context/attachments/attachmentsState';
import byteConverter from '../helpers/byteConverter';

let scrollToItemTimeout = null
const arrayStack = [-Infinity]


const excludedProgressVars = [...CONTACT_ID_VARIABLES]

const SingleDocument = ({ token }) => {
    const location = useLocation();
    const {
        documents,
        documentsLoaded,
        templates,
        templatesLoaded,
        getSingleTemplate,
        updateDocument,
        createDocument,
        createStandardTemplate,
        archivedDocuments,
        deletedDocuments
    } = useContext(DocumentsContext)
    const { setNotification } = useContext(NotificationContext)
    const { partner, user, agency, signInResult, siteConfigs } = useContext(UserContext)
    const { setShowGlobalResponseLoader, setGlobalResponseLoaderText, setLoading } =
        useContext(LoaderContext)
    const { setPrefilledAttachmentVariable } = useContext(AttachmentsContext)
    const {
        getDocumentHistory,
        getDocumentEventsDayArray,
        processedQueryVariables,
        joinValues,
        getPreviewData,
        // progress,
        allVariables,
        getCustomersFromData,
        extractCustomerData,
        downloadDocument,
    } = useSingleDocumentActions()
    const [documentId, setDocumentId] = useState('')
    const [singleDoc, setSingleDoc] = useState(null)
    const [singleDocReady, setSingleDocReady] = useState(false)
    const [singleDocNotFound, setSingleDocNotFound] = useState(false)
    const [singleDocArchived, setSingleDocArchived] = useState(false)
    const [singleDocDeleted, setSingleDocDeleted] = useState(false)
    const [singleDocHistory, setSingleDocHistory] = useState(null)
    const [singleDocEvents, setSingleDocEvents] = useState(null)
    const [singleDocName, setSingleDocName] = useState('')
    const [singleDocStatus, setSingleDocStatus] = useState('')
    // const [historyFetched, setHistoryFetched] = useState(false)
    const [scrollingToItem, setScrollingToItem] = useState(false)
 
    const [templateFetching, setTemplateFetching] = useState(false)
    const [documentGettingReady, setDocumentGettingReady] = useState(false)
    const [templateNotFound, setTemplateNotFound] = useState(false)
    const [documentCheckboxValues, setDocumentCheckboxValues] = useState({})
    const [useCustomCover, setUseCustomCover] = useState(false)
    const [linesAdded, setLinesAdded] = useState(false)
    const [sidePanelOpenStatus, setSidePanelOpenStatus] = useState({
        'variables': true,
        'attachments': false,
    })
    const [pdfPreviewData, setPdfPreviewData] = useState(null)
    const [previewLoading, setPreviewLoading] = useState(true)
    const [activeTab, setActiveTab] = useState('summary')
    const [templateObject, setTemplateObject] = useState(null)
    const [documentContacts, setDocumentContacts] = useState([])
    const [activeDocumentContacts, setActiveDocumentContacts] = useState([])
    const [docSignatureRecipients, setDocSignatureRecipients] = useState([])
    const [currentDocVersionTime, setCurrentDocVersionTime] = useState({})
    const [view, setView] = useState('variables')
    const [showAgencyModal, setShowAgencyModal] = useState(false)
    const [showNotariesModal, setShowNotariesModal] = useState(false)
    const [showAddEditNotaryModal, setShowAddEditNotaryModal] =
        useState(false)
    const [notaryMode, setNotaryMode] = useState('add')
    const [selectedNotary, setSelectedNotary] = useState(null)
    const [isTemplatePage, setIsTemplatePage] = useState(false)
    const { setIsScroll, isScroll } =
        useContext(UserContext)
    const [showMoreActions, setShowMoreActions] = useState(false)
    const [previewSettings, setPreviewSettings] = useState(null) // initialize with null so that initial preview uses document settings
    const [showEditionWarningModal, setShowEditionWarningModal] = useState(false)
    const params = useParams()
    const history = useHistory()
    const bodyWrapEl = useRef()

    // feat/editable-content-lite
    const [isContentEditableState, setIsContentEditable] = useState(null)
    const [partialContentChanges, setPartialContentChanges] = useState(null)
    const isContentEditable = isContentEditableState && isFeatureEnabled(FEATURE.EDITABLE_CONTENT_LITE, siteConfigs, partner, user)
    const [showOutdatedContentChangesModal, setShowOutdatedContentChangesModal] = useState(false)
    const [outdatedContentChangesNotified, setOutdatedContentChangesNotified] = useState(false)
    const [existingContentChanges, setExistingContentChanges] = useState([])

    // meta sections - for repeatable / conditional content
    const [templateMetaSections, setTemplateMetaSections] = useState([])
    const [summarySections, setSummarySections] = useState([])


    // share update
    const [shareAttachments, setShareAttachments] = useState([])

    const [progress, setProgress] = useState(0)

    const {
        documentValues,
        setDocumentValues,
        templateObjectWithUniqueVarIndexes,
        setTemplateObjectWithUniqueVarIndexes,
        filteredDefaultAttachments,
        documentAttachments,
        filteredDocumentAttachments,
        setDocumentAttachments,
    } = useContext(SingleDocumentContext)


    useEffect(() => {
        const activeContacts = getActiveContactsFromData(documentValues)
        setActiveDocumentContacts(activeContacts)
    }, [documentValues])

    useEffect(() => {
        if(!singleDoc) return
        if(singleDoc.custom_cover !== undefined) return
        setUseCustomCover(singleDoc.custom_cover ?? agency?.cover_page_default ?? true)
    }, [agency, singleDoc])

    useEffect(() => {

        if (!templateObjectWithUniqueVarIndexes) {
            return
        }

        const extractMetaBlocks = (sections) => {

            // Array.prototype.last = function() {
            //     return this[this.length - 1]
            // }

            const metaSections = []
            const currentMetaSectionStack = [{
                sections: [],
                repeatableIds: [],
                isMetaSection: true
            }]
            for (let i = 0; i < sections.length; i++) {
                const section = sections[i]
                section.index = i
                const sectionRepeatableIds = section.repeatable_section_ids || (section.repeatable_section_id ? [section.repeatable_section_id] : [])
                // check if section is sibling or direct child of current_full
                if (currentMetaSectionStack.length === 0) {
                    const newMetaSection = {
                        sections: [section],
                        repeatableIds: sectionRepeatableIds,
                        isMetaSection: true
                    }
                    currentMetaSectionStack.push(newMetaSection)
                } else if (areArraysEqual(sectionRepeatableIds, currentMetaSectionStack[currentMetaSectionStack.length - 1].repeatableIds)) {
                    currentMetaSectionStack[currentMetaSectionStack.length - 1].sections.push(section)
                } else if (sectionRepeatableIds.length > currentMetaSectionStack[currentMetaSectionStack.length - 1].repeatableIds.length) {
                    // validate if section is direct child of current
                    if (sectionRepeatableIds.length === currentMetaSectionStack[currentMetaSectionStack.length - 1].repeatableIds.length + 1 && currentMetaSectionStack[currentMetaSectionStack.length - 1].repeatableIds.length > 0 && areArraysEqual(sectionRepeatableIds.slice(0, currentMetaSectionStack[currentMetaSectionStack.length - 1].repeatableIds.length), currentMetaSectionStack[currentMetaSectionStack.length - 1].repeatableIds)) {
                        const newMetaSection = {
                            sections: [section],
                            repeatableIds: sectionRepeatableIds,
                            isMetaSection: true
                        }
                        currentMetaSectionStack.push(newMetaSection)
                    } else if (currentMetaSectionStack[currentMetaSectionStack.length - 1].repeatableIds.length === 0) {

                        const lastMetaSection = currentMetaSectionStack.pop()
                        if (lastMetaSection.sections.length > 0) {
                            metaSections.push(lastMetaSection)
                        }
                        const newMetaSection = {
                            sections: [section],
                            repeatableIds: sectionRepeatableIds,
                            isMetaSection: true
                        }
                        currentMetaSectionStack.push(newMetaSection)
                    } else {
                        throw new Error('Invalid section repeatable ids')
                    }
                } else if (sectionRepeatableIds.length < currentMetaSectionStack[currentMetaSectionStack.length - 1].repeatableIds.length) {
                    // jump out of current stack until we find a matching parent 
                    while (currentMetaSectionStack.length > 0 && (sectionRepeatableIds.length < currentMetaSectionStack[currentMetaSectionStack.length - 1].repeatableIds.length)) {
                        const closingSection = currentMetaSectionStack.pop()
                        if (currentMetaSectionStack.length === 0) {
                            metaSections.push(closingSection)
                        } else {
                            currentMetaSectionStack[currentMetaSectionStack.length - 1].sections.push(closingSection)
                        }
                    }
                    if (currentMetaSectionStack.length > 0) {
                        if (areArraysEqual(sectionRepeatableIds, currentMetaSectionStack[currentMetaSectionStack.length - 1].repeatableIds)) {
                            currentMetaSectionStack[currentMetaSectionStack.length - 1].sections.push(section)
                        } else {
                            const newMetaSection = {
                                sections: [section],
                                repeatableIds: sectionRepeatableIds,
                                isMetaSection: true
                            }
                            currentMetaSectionStack.push(newMetaSection)
                        }
                    } else {
                        const newMetaSection = {
                            sections: [section],
                            repeatableIds: sectionRepeatableIds,
                            isMetaSection: true
                        }
                        currentMetaSectionStack.push(newMetaSection)
                    }
                } else {
                    // are equal length but not equal
                    const closingSection = currentMetaSectionStack.pop()
                    if (currentMetaSectionStack.length === 0) {
                        metaSections.push(closingSection)
                    } else {
                        currentMetaSectionStack[currentMetaSectionStack.length - 1].sections.push(closingSection)
                    }
                    const newMetaSection = {
                        sections: [section],
                        repeatableIds: sectionRepeatableIds,
                        isMetaSection: true
                    }
                    currentMetaSectionStack.push(newMetaSection)
                }
            }
            while (currentMetaSectionStack.length > 0) {
                const closingSection = currentMetaSectionStack.pop()
                if (currentMetaSectionStack.length === 0) {
                    metaSections.push(closingSection)
                } else {
                    currentMetaSectionStack[currentMetaSectionStack.length - 1].sections.push(closingSection)
                }
            }
            return metaSections
        }

        const mSections = extractMetaBlocks(templateObjectWithUniqueVarIndexes.sections)
        setTemplateMetaSections(mSections)
        // return []
        // return metaSections

    }, [templateObjectWithUniqueVarIndexes])

    const { duplicatableValuesArray, value } = useVariablesActions({ values: documentValues })

    useEffect(() => {
        if (!templateMetaSections) {
            return
        }

        const extractSummaryRowsFromMetaSection = (metaSection, parentRepeatableIds, parentRepetitionIndices) => {
            const rows = []
            const { sections } = metaSection
            if (metaSection.repeatableIds && metaSection.repeatableIds.length > 0) {
                const repetitionValuesArray = duplicatableValuesArray([...(metaSection.repeatableIds || [])], parentRepetitionIndices)
                if (!repetitionValuesArray || repetitionValuesArray.length === 0) return []
                repetitionValuesArray.forEach((repetitionValues, repetitionIndex) => {
                    for (let i = 0; i < sections.length; i++) {
                        const subSection = sections[i]
                        if (subSection.isMetaSection) {
                            const subSectionRows = extractSummaryRowsFromMetaSection(subSection, [...(metaSection.repeatableIds || [])], [...(parentRepetitionIndices || []), repetitionIndex])
                            rows.push(...subSectionRows)
                            continue
                        }
                        if (!areSectionConditionsMet({ ...subSection, repetitionIndices: [...(parentRepetitionIndices || []), repetitionIndex] }, documentValues)) {
                            continue
                        }
                        if (subSection.type.startsWith('heading')) {
                            rows.push({
                                title: subSection.content,
                            })
                        }
                        if (subSection.variable) {
                            const row = { variable: subSection, repeatableIds: metaSection.repeatableIds, repetitionIndices: [...(parentRepetitionIndices || []), repetitionIndex] }
                            if (row.variable) {
                                row.value = repetitionValues[row.variable.variable]
                            }
                            rows.push(row)
                        }
                        if (subSection.variables) {
                            const variables = subSection.variables
                            for (let j = 0; j < variables.length; j++) {
                                const variable = variables[j]
                                const row = { variable, repeatableIds: metaSection.repeatableIds, repetitionIndices: [...(parentRepetitionIndices || []), repetitionIndex] }
                                if (row.variable) {
                                    row.value = repetitionValues[row.variable.variable]
                                }
                                rows.push(row)
                            }
                        }
                    }
                })

            } else {
                for (let i = 0; i < sections.length; i++) {
                    const subSection = sections[i]
                    if (subSection.isMetaSection) {
                        const subSectionRows = extractSummaryRowsFromMetaSection(subSection, [...(metaSection.repeatableIds || [])], parentRepetitionIndices)
                        rows.push(...subSectionRows)
                        continue
                    }
                    if (!areSectionConditionsMet({ ...subSection, repetitionIndices: parentRepetitionIndices }, documentValues)) {
                        continue
                    }
                    if (subSection.type.startsWith('heading')) {
                        rows.push({
                            title: subSection.content,
                        })
                    }
                    if (subSection.variable) {
                        const row = { variable: subSection, repeatableIds: metaSection.repeatableIds, repetitionIndices: parentRepetitionIndices }
                        if (row.variable) {
                            row.value = documentValues[row.variable.variable]
                        }
                        rows.push(row)
                    }
                    if (subSection.variables) {
                        const variables = subSection.variables
                        for (let j = 0; j < variables.length; j++) {
                            const variable = variables[j]
                            const row = { variable, repeatableIds: metaSection.repeatableIds, repetitionIndices: parentRepetitionIndices }
                            if (row.variable) {
                                row.value = documentValues[row.variable.variable]
                            }
                            rows.push(row)
                        }
                    }
                }
            }
            return rows
        }

        const rows = templateMetaSections.map((metaSection, metaSectionIndex) => extractSummaryRowsFromMetaSection(metaSection, [], []))
        const rowsFull = rows.flat()

        const sections = []
        let currentSummarySection = { title: '', fields: [] }
        for (let i = 0; i < rowsFull.length; i++) {
            const row = rowsFull[i]
            if (row.title) {
                if (currentSummarySection.fields.length > 0 || currentSummarySection.title) {
                    sections.push(currentSummarySection)
                }
                currentSummarySection = { title: row.title, fields: [] }
            } else if (row.variable) {
                currentSummarySection.fields.push({ ...row.variable, value: row.value, repeatableIds: row.repeatableIds, repetitionIndices: row.repetitionIndices })
            }
        }
        if (currentSummarySection.fields.length > 0 || currentSummarySection.title) {
            sections.push(currentSummarySection)
        }

        let totalProgress = 0
        let variablesTotal = 0
        let variablesDone = 0
        for (let i = 0; i < sections.length; i++) {
            const section = sections[i]
            const fields = section.fields
            let sectionVariablesTotal = 0
            let sectionVariablesDone = 0
            for (let j = 0; j < fields.length; j++) {
                const field = fields[j]
                if (field.variable && !excludedProgressVars.includes(field.variable)) {
                    sectionVariablesTotal++
                    if ((typeof field.value === 'string' && field.value !== '') || (typeof field.value !== 'string' && field.value !== undefined)) {
                        sectionVariablesDone++
                    }
                }
            }
            if (sectionVariablesTotal > 0) {
                section.progress = Math.round((sectionVariablesDone / sectionVariablesTotal) * 100)
            }
            variablesTotal += sectionVariablesTotal
            variablesDone += sectionVariablesDone
        }
        totalProgress = variablesTotal > 0 ? (variablesDone / variablesTotal) : 1
        setProgress(totalProgress)
        setSummarySections(sections)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [templateMetaSections, documentValues])

    const applyContentChangesToTemplate = (t, changes) => {
        let hasOutdatedChanges = false
        let template = JSON.parse(JSON.stringify(t))
        for (let change of changes) {
            let applied = false
            if(template.version_code && change.template_version !== template.version_code) {
                hasOutdatedChanges = true
                // console.log(`Skipping outdated change`, change)
                continue
            }
            for (let sIndex = 0; sIndex < template.sections.length; sIndex++) {
                if (sIndex === change.sectionIndex) {
                    if (change.listIndex !== -1 && template.sections[sIndex].items) {
                        for (let lIndex = 0; lIndex < template.sections[sIndex].items.length; lIndex++) {
                            if (lIndex === change.listIndex) {
                                template.sections[sIndex].items[lIndex].content = change.content
                                applied = true
                                break
                            }
                        }
                    } else if (change.listIndex === -1) {
                        template.sections[sIndex].content = change.content
                        applied = true
                    }
                }
                if (applied) {
                    break
                }
            }
        }
        if(hasOutdatedChanges && !outdatedContentChangesNotified) {
            setOutdatedContentChangesNotified(true)
            setShowOutdatedContentChangesModal(true)
        }
        return template
    }

    const handleToggleContentEditable = () => {
        if (!isContentEditable) {
            setShowEditionWarningModal(true)
        }
        setIsContentEditable(!isContentEditable)
    }

    useEffect(() => {
        if (!templateObject) return
        if (!singleDoc) return
        if(!templateObject.sections) return

        const copyOfTemplate = convertToTemplateObjWithUniqueVarIndexes(
            !isContentEditable ?
                { ...templateObject } :
                applyContentChangesToTemplate(templateObject, buildContentChanges(partialContentChanges, templateObject, existingContentChanges, true))
        )
        setTemplateObjectWithUniqueVarIndexes(copyOfTemplate);
    }, [isContentEditable, templateObject, singleDoc, partialContentChanges, setTemplateObjectWithUniqueVarIndexes, outdatedContentChangesNotified, existingContentChanges])

    const onSectionContentChanged = (change) => {

        // change format
        // sectionIndex: index,
        // listIndex: listIndex,
        // editedContent,
        // originalContent

        // console.log('change', change)

        const pcc = addPartialContentChange([...(partialContentChanges || [])], change)
        setPartialContentChanges([...pcc])
    }

    const addPartialContentChange = (changes, change) => {
        let isNew = true
        for (let i in changes) {
            if (changes[i].sectionIndex === change.sectionIndex && changes[i].listIndex === change.listIndex && changes[i].originalContent === change.originalContent) {
                isNew = false
                changes[i].editedContent = change.editedContent
                break
            }
        }
        if (isNew) {
            changes.push(change)
        }
        return changes
    }


    const renderOptions = useCallback((document, template) => {
        const agencyFontConfig = () => {
            if (!isFeatureEnabled(FEATURE.DOCUMENT_CUSTOMIZATION, [], partner, user)) {
                return {}
            }
            if (!agency) {
                return {}
            }
            const font_config = {
                heading_2: {
                    color: agency.cover_color,
                    borderColor: agency.cover_color,
                },
                heading_3: {
                    color: "#ffffff",
                    backgroundColor: agency.cover_color,
                },
                footer_title: {
                    color: agency.cover_color,
                },
                cover_title: {
                    color: agency.cover_color,
                }
            }
            return font_config
        }


        const agencyConfig = () => {
            if (!agency) {
                return {}
            }
            const config = {
                cover_color: agency.cover_color,
            }
            return config
        }

        const agencyImages = () => {
            if (!agency) {
                return {}
            }
            const images = {
                logo: agency.logo,
                cover: agency.cover,
            }
        }

        const shouldApplyContentChanges = (document) => {
            if (isContentEditable === null) {
                return document?.content_editable
            }
            return isContentEditable
        }

        const options = {
            font_config: {
                font_size: previewSettings?.size || document?.font_size || 'normal',
                ...agencyFontConfig(),
            },
            use_watermark: previewSettings?.watermark || document?.use_watermark || false,
            ...agencyConfig(),
            contentChanges:
                shouldApplyContentChanges(document) ? buildContentChanges(partialContentChanges, template, existingContentChanges) : []
        }
        return options
    }, [previewSettings, partialContentChanges, agency, isContentEditable, partner, user, existingContentChanges])

    // On document preview
    const handleDocumentPreview = useCallback(
        async (doc, values, template, initialPreview) => {

            // setShowSidePanel(true)
            setPreviewLoading(true)
            setActiveTab('preview')
            let d = singleDoc
            if (doc) d = doc
            let dValues = documentValues
            if (values) dValues = values
            let t = templateObject
            if (template) t = template
            const data = await getPreviewData(
                { 
                    ...d, 
                    values: dValues,
                    name: initialPreview ? doc.name : singleDocName,
                    checkboxValues: documentCheckboxValues,
                    custom_cover: initialPreview ? 
                        (doc.custom_cover ?? agency?.cover_page_default)
                        : useCustomCover,
                    empty_lines_added: initialPreview ? doc.empty_lines_added : linesAdded,
                    content_changes: !(initialPreview ? doc.content_editable : isContentEditable) ? [] : buildContentChanges([], t, initialPreview ? (doc.content_changes || []) : existingContentChanges)
                },
                t,
                partner === 'jurisur' &&
                config.environment === 'development',
                renderOptions(doc, template)
            )
            if (!data) {
                setPreviewLoading(false)
                setPdfPreviewData(null)
                return
            }
            setPdfPreviewData(data)
            setPreviewLoading(false)
            // eslint-disable-next-line
        },
        [documentValues, singleDoc, templateObject, useCustomCover, documentCheckboxValues, partner, getPreviewData, renderOptions, singleDocName, linesAdded, isContentEditable, existingContentChanges, agency]
    )

    const handleDocumentDownload = async (extension = 'pdf') => {
        setLoading(true)

        await downloadDocument(extension, {
            ...singleDoc,
            values: {
                ...(singleDoc.values || {}),
                ...documentValues,
            },
            name: singleDocName,
            custom_cover: useCustomCover,
            empty_lines_added: linesAdded,
        },
            renderOptions(singleDoc, templateObjectWithUniqueVarIndexes)
        )
        if (singleDoc.id) {
            await add_document_event(singleDoc.id, {
                type: `download_${extension}`,
                values: documentValues,
                content_changes: isContentEditable ? buildContentChanges(partialContentChanges, templateObject, existingContentChanges) : [],
            })
        }
        setLoading(false)
    }

    const handleFetchDocumentHistory = async () => {

        const fetchHistory = async (id, doc) => {
            try {
                const res = await fetch_document_history(id)
                const arr = getDocumentHistory(res)
                setSingleDocHistory(arr)
                if (arr[0]) {
                    setCurrentDocVersionTime({
                        time: arr[0].versions[arr[0].versions.length - 1]
                            .created,
                    })
                } else {
                    setCurrentDocVersionTime({ time: doc.meta.created })
                }
            } catch (err) {
                console.log(err)
            }
        }

        const fetchEvents = async (id) => {
            try {
                const res = await fetch_document_events(id)
                const arr = getDocumentEventsDayArray(res)
                setSingleDocEvents(arr)
            } catch (err) {
                setSingleDocEvents([])
                console.log(err)
            }
        }

        await fetchHistory(params.id, singleDoc)
        await fetchEvents(params.id)
    }

    // Set document based on id
    useEffect(() => {
        // Prefill values with admin data
        const prefillValuesWithAdminData = (values) => {
            if (!user || !signInResult || !user.manufacturer) {
                return
            }

            const variablesToPrefill = [
                {
                    variable_name: 'adr_caisse',
                    value: user.manufacturer.adr_caisse || '',
                },
                {
                    variable_name: 'adr_caisse_gestion',
                    value: user.manufacturer.adr_caisse_gestion || '',
                },
                {
                    variable_name: 'adr_caisse_syndic',
                    value: user.manufacturer.adr_caisse_syndic || '',
                },
                {
                    variable_name: 'adr_prefecture_carte_pro',
                    value: user.manufacturer.adr_prefecture_carte_pro || '',
                },
                {
                    variable_name: 'adr_prefecture_carte_pro_gestion',
                    value:
                        user.manufacturer.adr_prefecture_carte_pro_gestion ||
                        '',
                },
                {
                    variable_name: 'adr_prefecture_carte_pro_syndic',
                    value:
                        user.manufacturer.adr_prefecture_carte_pro_syndic || '',
                },
                {
                    variable_name: 'adresse',
                    value: user.manufacturer.adresse || user.manufacturer.address || '',
                },
                {
                    variable_name: 'adresse_siege',
                    value: user.manufacturer.adresse_siege || '',
                },
                {
                    variable_name: 'banque_sequestre',
                    value: user.manufacturer.banque_sequestre || '',
                },
                {
                    variable_name: 'caisse',
                    value: user.manufacturer.caisse || '',
                },
                {
                    variable_name: 'caisse_gestion',
                    value: user.manufacturer.caisse_gestion || '',
                },
                {
                    variable_name: 'caisse_syndic',
                    value: user.manufacturer.caisse_syndic || '',
                },
                {
                    variable_name: 'capital',
                    value: user.manufacturer.capital || '',
                },
                { variable_name: 'city', value: user.manufacturer.city || '' },
                {
                    variable_name: 'code_ape',
                    value: user.manufacturer.code_ape || '',
                },
                {
                    variable_name: 'compte_sequestre',
                    value: user.manufacturer.compte_sequestre || '',
                },
                {
                    variable_name: 'cp_prefecture_carte_pro',
                    value: user.manufacturer.cp_prefecture_carte_pro || '',
                },
                {
                    variable_name: 'cp_prefecture_carte_pro_gestion',
                    value:
                        user.manufacturer.cp_prefecture_carte_pro_gestion || '',
                },
                {
                    variable_name: 'cp_prefecture_carte_pro_syndic',
                    value:
                        user.manufacturer.cp_prefecture_carte_pro_syndic || '',
                },
                {
                    variable_name: 'cp_siege',
                    value: user.manufacturer.cp_siege || '',
                },
                {
                    variable_name: 'date_de_creation',
                    value: user.manufacturer.date_de_creation || '',
                },
                {
                    variable_name: 'date_de_creation_gestion',
                    value: user.manufacturer.date_de_creation_gestion || '',
                },
                {
                    variable_name: 'date_de_creation_syndic',
                    value: user.manufacturer.date_de_creation_syndic || '',
                },
                {
                    variable_name: 'delivree_par_prefecture',
                    value: user.manufacturer.delivree_par_prefecture || '',
                },
                {
                    variable_name: 'delivree_par_prefecture_gestion',
                    value:
                        user.manufacturer.delivree_par_prefecture_gestion || '',
                },
                {
                    variable_name: 'delivree_par_prefecture_syndic',
                    value:
                        user.manufacturer.delivree_par_prefecture_syndic || '',
                },
                {
                    variable_name: 'detenteur_carte_pro',
                    value: user.manufacturer.detenteur_carte_pro || '',
                },
                {
                    variable_name: 'detenteur_carte_pro_gestion',
                    value: user.manufacturer.detenteur_carte_pro_gestion || '',
                },
                {
                    variable_name: 'detenteur_carte_pro_syndic',
                    value: user.manufacturer.detenteur_carte_pro_syndic || '',
                },
                {
                    variable_name: 'detention_fonds',
                    value: user.manufacturer.detention_fonds || '',
                },
                { variable_name: 'dpo', value: user.manufacturer.dpo || '' },
                {
                    variable_name: 'email',
                    value: user.manufacturer.email || '',
                },
                {
                    variable_name: 'email_location',
                    value: user.manufacturer.email_location || '',
                },
                { variable_name: 'fax', value: user.manufacturer.fax || '' },
                {
                    variable_name: 'filiale',
                    value: user.manufacturer.filiale || '',
                },
                {
                    variable_name: 'forme',
                    value: user.manufacturer.forme || '',
                },
                {
                    variable_name: 'holding',
                    value: user.manufacturer.holding || '',
                },
                {
                    variable_name: 'horaires',
                    value: user.manufacturer.horaires || '',
                },
                {
                    variable_name: 'jours_horaires',
                    value: user.manufacturer.jours_horaires || '',
                },
                {
                    variable_name: 'last_modified',
                    value: user.manufacturer.last_modified || '',
                },
                {
                    variable_name: 'latitude',
                    value: user.manufacturer.latitude || 0,
                },
                {
                    variable_name: 'legal_mention',
                    value: user.manufacturer.legal_mention || '',
                },
                {
                    variable_name: 'longitude',
                    value: user.manufacturer.longitude || 0,
                },
                {
                    variable_name: 'manufacturers_contact_email',
                    value: user.manufacturer.manufacturers_contact_email || '',
                },
                {
                    variable_name: 'manufacturers_contact_firstname',
                    value:
                        user.manufacturer.manufacturers_contact_firstname || '',
                },
                {
                    variable_name: 'manufacturers_contact_name',
                    value: user.manufacturer.manufacturers_contact_name || '',
                },
                {
                    variable_name: 'manufacturers_contact_telephone',
                    value:
                        user.manufacturer.manufacturers_contact_telephone || '',
                },
                {
                    variable_name: 'manufacturers_description',
                    value: user.manufacturer.manufacturers_description || '',
                },
                {
                    variable_name: 'manufacturers_fonction',
                    value: user.manufacturer.manufacturers_fonction || '',
                },
                {
                    variable_name: 'manufacturers_gender',
                    value: user.manufacturer.manufacturers_gender || '',
                },
                {
                    variable_name: 'manufacturers_image',
                    value: user.manufacturer.manufacturers_image || '',
                },
                {
                    variable_name: 'manufacturers_logo',
                    value: user.manufacturer.manufacturers_logo || '',
                },
                {
                    variable_name: 'manufacturers_name',
                    value: user.manufacturer.manufacturers_name || user.manufacturer.name || '',
                },
                {
                    variable_name: 'manufacturers_url',
                    value: user.manufacturer.manufacturers_url || '',
                },
                {
                    variable_name: 'manufacturers_url_googleplus',
                    value: user.manufacturer.manufacturers_url_googleplus || '',
                },
                {
                    variable_name: 'manufacturers_url_instagram',
                    value: user.manufacturer.manufacturers_url_instagram || '',
                },
                {
                    variable_name: 'manufacturers_url_like',
                    value: user.manufacturer.manufacturers_url_like || '',
                },
                {
                    variable_name: 'manufacturers_url_linkedin',
                    value: user.manufacturer.manufacturers_url_linkedin || '',
                },
                {
                    variable_name: 'manufacturers_url_pinterest',
                    value: user.manufacturer.manufacturers_url_pinterest || '',
                },
                {
                    variable_name: 'manufacturers_url_twitter',
                    value: user.manufacturer.manufacturers_url_twitter || '',
                },
                {
                    variable_name: 'manufacturers_url_youtube',
                    value: user.manufacturer.manufacturers_url_youtube || '',
                },
                {
                    variable_name: 'mediateur_adresse',
                    value: user.manufacturer.mediateur_adresse || '',
                },
                {
                    variable_name: 'mediateur_date_obtention_label',
                    value:
                        user.manufacturer.mediateur_date_obtention_label || '',
                },
                {
                    variable_name: 'mediateur_nom',
                    value: user.manufacturer.mediateur_nom || '',
                },
                {
                    variable_name: 'mediateur_site_internet',
                    value: user.manufacturer.mediateur_site_internet || '',
                },
                {
                    variable_name: 'mention_carte_pro',
                    value: user.manufacturer.mention_carte_pro || '',
                },
                {
                    variable_name: 'mention_carte_pro_gestion',
                    value: user.manufacturer.mention_carte_pro_gestion || '',
                },
                {
                    variable_name: 'mention_carte_pro_syndic',
                    value: user.manufacturer.mention_carte_pro_syndic || '',
                },
                {
                    variable_name: 'montant_caisse',
                    value: user.manufacturer.montant_caisse || '',
                },
                {
                    variable_name: 'montant_caisse_gestion',
                    value: user.manufacturer.montant_caisse_gestion || '',
                },
                {
                    variable_name: 'montant_caisse_syndic',
                    value: user.manufacturer.montant_caisse_syndic || '',
                },
                {
                    variable_name: 'no_caisse',
                    value: user.manufacturer.no_caisse || '',
                },
                {
                    variable_name: 'no_caisse_gestion',
                    value: user.manufacturer.no_caisse_gestion || '',
                },
                {
                    variable_name: 'no_caisse_syndic',
                    value: user.manufacturer.no_caisse_syndic || '',
                },
                {
                    variable_name: 'no_carte_pro',
                    value: user.manufacturer.no_carte_pro || '',
                },
                {
                    variable_name: 'no_carte_pro_gestion',
                    value: user.manufacturer.no_carte_pro_gestion || '',
                },
                {
                    variable_name: 'no_carte_pro_syndic',
                    value: user.manufacturer.no_carte_pro_syndic || '',
                },
                {
                    variable_name: 'no_rc',
                    value: user.manufacturer.no_rc || '',
                },
                {
                    variable_name: 'nom_caisse_regionale',
                    value: user.manufacturer.nom_caisse_regionale || '',
                },
                {
                    variable_name: 'numero_orias',
                    value: user.manufacturer.numero_orias || '',
                },
                {
                    variable_name: 'postal_code',
                    value: user.manufacturer.postal_code || '',
                },
                { variable_name: 'rcp', value: user.manufacturer.rcp || '' },
                {
                    variable_name: 'rcp_adresse',
                    value: user.manufacturer.rcp_adresse || '',
                },
                {
                    variable_name: 'rcp_organisme',
                    value: user.manufacturer.rcp_organisme || '',
                },
                { variable_name: 'rcs', value: user.manufacturer.rcs || '' },
                {
                    variable_name: 'reseau',
                    value: user.manufacturer.reseau || '',
                },
                {
                    variable_name: 'secteur',
                    value: user.manufacturer.secteur || '',
                },
                {
                    variable_name: 'statut_name',
                    value: user.manufacturer.statut_name || '',
                },
                {
                    variable_name: 'telephone',
                    value: user.manufacturer.telephone || user.manufacturer.phone || '',
                },
                {
                    variable_name: 'tva_intra',
                    value: user.manufacturer.tva_intra || '',
                },
                {
                    variable_name: 'type_mandataire',
                    value: user.manufacturer.type_mandataire || '',
                },
                {
                    variable_name: 'ville_prefecture_carte_pro',
                    value: user.manufacturer.ville_prefecture_carte_pro || '',
                },
                {
                    variable_name: 'ville_prefecture_carte_pro_gestion',
                    value:
                        user.manufacturer.ville_prefecture_carte_pro_gestion ||
                        '',
                },
                {
                    variable_name: 'ville_prefecture_carte_pro_syndic',
                    value:
                        user.manufacturer.ville_prefecture_carte_pro_syndic ||
                        '',
                },
                {
                    variable_name: 'ville_rcs',
                    value: user.manufacturer.ville_rcs || '',
                },
                {
                    variable_name: 'ville_siege',
                    value: user.manufacturer.ville_siege || '',
                },
            ]

            for (let v of variablesToPrefill) {
                values[v.variable_name] = v.value
                if (!values[v.variable_name] && signInResult?.agency && signInResult?.agency[v.variable_name]) {
                    values[v.variable_name] = signInResult?.agency[v.variable_name]
                }
            }
        }

        // Setup document
        const setupDocument = async (templatePage = false) => {
            setIsTemplatePage(templatePage)
            console.log('setup doc****************')
            setDocumentGettingReady(true)
            let doc
            let template
            if (templatePage) {
                template = templates[params.id]
                doc = {
                    template: params.id,
                    name: template.name,
                }
            } else {
                doc = documents[params.id]
                template = templates[doc.template]
            }
            if (!template) {
                setTemplateNotFound(true)
                return
            }
            setSingleDoc(doc)
            setDocumentId(params.id)
            if (
                !template.sections &&
                !templateFetching
            ) {
                setTemplateFetching(true)
                template = await getSingleTemplate(doc.template, true)
                setTemplateFetching(false)
            }
            setTemplateObject(template)

            let prefillValues
            const queryStringVariables = qs.parse(history.location.search, {
                ignoreQueryPrefix: true,
            })

            if (queryStringVariables.data_id) {
                // fetch data object
                let data = await fetch_document_data(
                    queryStringVariables.data_id
                )
                console.log('doc data**', data)

                if (!data.error) {
                    extractCustomerData(data)
                    prefillValues = data
                } else {
                    // todo display error
                }
            } else {
                prefillValues = processedQueryVariables(
                    template,
                    queryStringVariables
                )
            }
            prefillValuesWithAdminData(prefillValues)
            let existingDocumentValuesWithPrefillData = joinValues(
                doc.values || {},
                prefillValues
            )
            extractCustomerData(existingDocumentValuesWithPrefillData)
            setDocumentValues(existingDocumentValuesWithPrefillData)
            setInitialDocumentState(existingDocumentValuesWithPrefillData)
            setDocumentCheckboxValues(doc.checkboxValues || {})
            setSingleDocName(doc.name)
            setSingleDocStatus(doc.status)
            setDocumentAttachments(doc.attachments || [])
            setInitialDocAttachments(doc.attachments || [])

            setIsContentEditable(doc.content_editable || false)
            setExistingContentChanges(doc.content_changes || [])

            if (doc.content_editable) {
                template = applyContentChangesToTemplate(template, buildContentChanges(partialContentChanges, template, doc.content_changes || [], false))
            }

            const copyOfTemplate = convertToTemplateObjWithUniqueVarIndexes(
                { ...template }
            )
            setTemplateObjectWithUniqueVarIndexes(copyOfTemplate)
            setUseCustomCover(doc.custom_cover ?? agency?.cover_page_default ?? true)
            // DEFAULT { size: 'normal', watermark: false }
            setPreviewSettings({
                watermark: doc.use_watermark || false,
                size: doc.font_size || 'normal',
            })
            setLinesAdded(doc.empty_lines_added || false)
            setShareAttachments(doc.share_attachments || [])
            handleDocumentPreview(
                doc,
                existingDocumentValuesWithPrefillData,
                template,
                true
            )
            getCustomersFromData(
                existingDocumentValuesWithPrefillData,
                setDocumentContacts
            )
            setDocSignatureRecipients(doc.signatureRecipients || [])
            setSingleDocReady(true)
            handleFetchDocumentHistory()
        }

        let components = history.location.pathname.split('/')
        const documentPaths = [
            'documents',
            'document-detail',
            'v2-documents',
        ]
        const templatePaths = ['templates', 'template', 'v2-templates']
        let location = components[1]
        if (params.id && documentsLoaded && templatesLoaded && partner) {
            if (documentPaths.includes(location)) {
                if (documents[params.id]) {
                    if (
                        (!singleDocReady && !documentGettingReady) ||
                        params.id !== documentId
                    ) {
                        setupDocument()
                    }
                    setSingleDocNotFound(false)
                } else {
                    setSingleDocArchived(Boolean(archivedDocuments[params.id]))
                    setSingleDocDeleted(Boolean(deletedDocuments[params.id]))
                    setSingleDocNotFound(true)
                    setSingleDocReady(true)
                }
            } else if (templatePaths.includes(location)) {
                if (templates[params.id]) {
                    if (!singleDocReady && !documentGettingReady) {
                        setupDocument(true)
                    }
                    setSingleDocNotFound(false)
                } else {
                    setTemplateNotFound(true)
                    // setSingleDocNotFound(true)
                    setSingleDocReady(true)
                }
            } else {
                // invalid paths
            }
        }
    }, [
        location,
        documentId,
        params.id,
        documents,
        templates,
        documentsLoaded,
        templatesLoaded,
        singleDocReady,
        getSingleTemplate,
        templateFetching,
        documentGettingReady,
        history.location.search,
        history.location.pathname,
        joinValues,
        processedQueryVariables,
        handleDocumentPreview,
        partner,
        getCustomersFromData,
        extractCustomerData,
        signInResult,
        user,
        partialContentChanges,
        archivedDocuments,
        deletedDocuments
    ])

    // Cleanup
    useEffect(() => {
        return () => {
            if (scrollToItemTimeout) {
                clearTimeout(scrollToItemTimeout)
            }
        }
    }, [])

    useEffect(() => {
        // update document attachments when document is saved
        setDocumentAttachments(documents[params.id]?.attachments || [])
    }, [params.id, documents, setDocumentAttachments])

    // prevent user from changing route when have unsaved data
    const [isBlocking, setIsBlocking] = useState(false);
    const [initialDocumentState, setInitialDocumentState] = useState();
    const [initialDocAttachments, setInitialDocAttachments] = useState();

    useEffect(() => {
        let block = false
        if (documentValues) {
            if (!areObjectsEqual(initialDocumentState, documentValues)) {
                block = true
            }
        }
        if(documentAttachments) {
            if(!areObjectsEqual(initialDocAttachments, documentAttachments)) {
                block = true
            }
        }
        setIsBlocking(block)
    }, [documentValues, initialDocumentState, documentAttachments, initialDocAttachments]);

    // Reset setup
    const resetSetup = () => {
        setSingleDocReady(false)
        setDocumentGettingReady(false)
    }

    const findEmptyVariableInSection = (section, repetitionIds, repetitionIndices) => {
        if(section.variable) {
            const val = value(section.variable, repetitionIds, repetitionIndices)
            if(!val) {
                return {
                    variable: {...section},
                    repetitionIds: repetitionIds,
                    repetitionIndices: repetitionIndices
                }
            }
        } else if(section.variables) {
            for(let va of section.variables) {
                const val = value(va.variable, repetitionIds, repetitionIndices)
                if(!val) {
                    return {
                        variable: va,
                        repetitionIds: repetitionIds,
                        repetitionIndices: repetitionIndices
                    }
                }
            }
        } else if(section.items) {
            for(let i = 0; i < section.items.length; i++) {
                const item = section.items[i]
                if(item.variable) {
                    const val = value(item.variable, repetitionIds, repetitionIndices)
                    if(!val) {
                        return {
                            variable: {...item},
                            repetitionIds: repetitionIds,
                            repetitionIndices: repetitionIndices
                        }
                    }
                }
                if(item.variables) {
                    for(let va of item.variables) {
                        const val = value(va.variable, repetitionIds, repetitionIndices)
                        if(!val) {
                            return {
                                variable: va,
                                repetitionIds: repetitionIds,
                                repetitionIndices: repetitionIndices
                            }
                        }
                    }
                }
            }
        }
    }

    const findFirstEmptyVariable = () => {
        for(let section of templateObjectWithUniqueVarIndexes.sections) {
            const repIds = section.repeatable_section_ids || section.repeatable_section_id ? [section.repeatable_section_id] : []
            if(repIds.length === 1) {
                const repetitionValuesArray = duplicatableValuesArray([...repIds], [])
                for(let i = 0; i < repetitionValuesArray.length; i++) {
                    const emptyVariable = findEmptyVariableInSection(section, repIds, [i])
                    if(emptyVariable) {
                        return emptyVariable
                    }
                }
            } else if(repIds.length === 2) {
                const outerRepetitionValuesArray = duplicatableValuesArray([repIds[0]], [])
                for(let i = 0; i < outerRepetitionValuesArray.length; i++) {
                    const innerRepetitionValuesArray = duplicatableValuesArray([repIds[0], repIds[1]], [i])
                    for(let j = 0; j < innerRepetitionValuesArray.length; j++) {
                        const emptyVariable = findEmptyVariableInSection(section, repIds, [i, j])
                        if(emptyVariable) {
                            return emptyVariable
                        }
                    }
                }
            } else {
                const emptyVariable = findEmptyVariableInSection(section, [], [])
                if(emptyVariable) {
                    return emptyVariable
                }
            }
        }
    }

    const handleNavigateToEmptyVariable = () => {
        const variableInfo = findFirstEmptyVariable()
        if(!variableInfo) {
            return
        }
        const element = bodyWrapEl.current.querySelector(
            `[data-id="${variableInfo.variable.idx}"]`
        )
        if(!element) {
            return
        }
        const input =
            element.querySelector('input') ||
            element.querySelector('textarea')
        if (input) {
            input.focus()
        }
        setScrollingToItem(true)
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        scrollToItemTimeout = setTimeout(
            () => setScrollingToItem(false),
            1000
        )
    }

    // Handle variable item click
    const handleVariableItemClick = useCallback((id) => {
        const element = bodyWrapEl.current.querySelector(
            `[data-id="${id}"]`
        )
        if (element) {
            const input =
                element.querySelector('input') ||
                element.querySelector('textarea')
            if (input) {
                input.focus()
            }
            setScrollingToItem(true)
            console.log(element.getBoundingClientRect().top)
            element.scrollIntoView({ behavior: 'smooth', block: 'start' });
            // I managed offset using scroll-margin in CSS
            scrollToItemTimeout = setTimeout(
                () => setScrollingToItem(false),
                1000
            )
        }
    }, [])

    // Handle section click
    const handleSectionClick = useCallback((id) => {
        const element = bodyWrapEl.current.querySelector(
            `[data-id="section-${id}"]`
        )
        if (element) {
            setScrollingToItem(true)
            element.scrollIntoView({ behavior: 'smooth', block: 'start' });
            // I managed offset using scroll-margin in CSS
            scrollToItemTimeout = setTimeout(
                () => setScrollingToItem(false),
                1000
            )
        }
    }, [])

    // On document values change
    const handleDocumentValuesChange = useCallback(
        (variable, value) => {
            // console.log("handleDocumentValuesChange", variable, value), value)
            if (typeof variable === 'object' && Array.isArray(variable)) {
                let dv = { ...documentValues }
                for (let i in variable) {
                    dv[variable[i]] = value[i]
                    if(value[i] === null) {
                        delete dv[variable[i]]
                    }
                }
                setDocumentValues((values) => ({ ...values, ...dv, }))
            } else {
                if(value === null) {
                    setDocumentValues((values) => {
                        delete values[variable]
                        return { ...values }
                    })
                } else {
                    setDocumentValues((values) => ({ ...values, [variable]: value }))
                }
            }
        },
        [documentValues]
    )

    // On document checkbox values change
    const handleDocumentCheckboxValuesChange = useCallback(
        (id, value) => {
            setDocumentCheckboxValues({
                ...documentCheckboxValues,
                [id]: value,
            })
        },
        [documentCheckboxValues]
    )

    // On side panel toggle
    const handleToggleSidePanel = useCallback((value) => {
        let show = !sidePanelOpenStatus[view]
        if(value !== undefined) {
            show = value
        }
        if (view === 'variables' && show) {
            handleDocumentPreview(singleDoc, documentValues, templateObject)
        }
        setSidePanelOpenStatus((status) => ({
            ...status,
            [view]: show,
        }))
    }, [sidePanelOpenStatus, view, handleDocumentPreview, singleDoc, documentValues, templateObject])

    // On tab change - only on smaller screens max-width 768px
    const handleTabChange = (e, tab, attachments = false) => {
        e.preventDefault()
        setActiveTab(tab)
        if (tab === 'preview') {
            handleDocumentPreview(singleDoc, documentValues, templateObject)
        }
        if (attachments) {
            setView('attachments')
        } else {
            setView('variables')
        }
    }

    const handleNavbarDocumentPreview = useCallback(() => {
        if (!sidePanelOpenStatus.variables) {
            handleToggleSidePanel()
        } else {
            handleDocumentPreview(singleDoc, documentValues, templateObject)
        }
    }, [sidePanelOpenStatus, handleToggleSidePanel, handleDocumentPreview, documentValues, singleDoc, templateObject])

    const handleCreateStandardTemplate = async () => {
        // TODO create standart template
        setShowGlobalResponseLoader(true, 2000)
        setGlobalResponseLoaderText('Ouverture du modèle type créé')
        // history.push(`/documents/create-standard-template/${documentId}`)
        const standardTemplateResponse = await createStandardTemplate({
            document: singleDoc,
            template: templateObject,
            values: documentValues,
            checkboxValues: documentCheckboxValues,
            name: singleDocName,
            customCover: useCustomCover,
            attachments: documentAttachments,
            content_changes: isContentEditable ? buildContentChanges(partialContentChanges, templateObject, existingContentChanges) : [],
            content_editable: isContentEditable,
        })
        if (standardTemplateResponse.error) {
            return
        }
        history.push(`/standard-templates/${standardTemplateResponse.id}`)
        setShowGlobalResponseLoader(false)
    }

    // On save
    const handleSave = useCallback(async () => {
        setShowGlobalResponseLoader(true)
        setGlobalResponseLoaderText('Sauvegarde du document');
        if (!isContentEditable) {
            setPartialContentChanges([])
        }
        try {
            let docValues = documentValues
            if (isTemplatePage) {
                let data = {
                    values: {},
                    checkboxValues: {},
                    name: templateObject.name,
                    status: 'draft',
                    template: templateObject.id,
                    progress: 0,
                    folderId: [],
                    custom_cover: useCustomCover,
                    content_changes: isContentEditable ? buildContentChanges(partialContentChanges, templateObject, existingContentChanges, true, true) : [],
                    content_editable: isContentEditable,
                    use_watermark: previewSettings?.watermark !== undefined ? previewSettings.watermark : false,
                    font_size: previewSettings?.size || 'normal',
                    attachments: documentAttachments || [],
                    empty_lines_added: linesAdded,
                }
                if (docValues) {
                    data.values = docValues
                }
                try {
                    const id = await createDocument(data)
                    setSingleDocName(templateObject.name)
                    resetSetup()
                    history.replace(`/documents/${id}`)
                } catch (err) {
                    setNotification({
                        msg: 'Une erreur est survenue, merci de réessayer',
                        type: 'danger',
                    })
                }
            } else {
                let documentUpdates = {
                    values: docValues,
                    checkboxValues: documentCheckboxValues,
                    name: singleDocName,
                    status: singleDocStatus,
                    progress: progress,
                    custom_cover: useCustomCover,
                    use_watermark: previewSettings?.watermark !== undefined ? previewSettings.watermark : singleDoc.use_watermark || false,
                    font_size: previewSettings?.size || singleDoc.font_size || 'normal',
                    attachments: documentAttachments,
                    content_changes: isContentEditable ? buildContentChanges(partialContentChanges, templateObject, existingContentChanges, true, true) : [],
                    content_editable: isContentEditable,
                    empty_lines_added: linesAdded,
                }
                await updateDocument(documentUpdates, {
                    ...singleDoc,
                    id: documentId,
                    attachments: documentAttachments,
                })
                setSingleDoc((doc) => ({
                    ...doc,
                    ...documentUpdates,
                }))
                setCurrentDocVersionTime({ time: Date.now() })
            }
            setDocumentValues(docValues)
            setInitialDocumentState(docValues)
            setInitialDocAttachments([...(documentAttachments || [])])
        } catch (err) {
            console.error("handleSave", err)
            setNotification({
                msg: 'Une erreur est survenue, merci de réessayer',
                type: 'danger',
            })
        } finally {
            setShowGlobalResponseLoader(false)
            setGlobalResponseLoaderText('')
        }
        // eslint-disable-next-line
    }, [
        documentCheckboxValues,
        documentId,
        documentValues,
        singleDocName,
        singleDocStatus,
        templateObject,
        singleDoc,
        useCustomCover,
        documentAttachments,
        isContentEditable,
        partialContentChanges,
        previewSettings,
        progress,
        linesAdded
    ])

    // On name change
    const handleNameChange = useCallback((e) => {
        const name = e.currentTarget.textContent
        setSingleDocName(name)
    }, [])

    // On attachment share save
    const handleAttachmentShareSave = async (shareAttachments) => {
        if (documentId) {
            setShowGlobalResponseLoader(true)
            await updateDocument(
                {
                    share_attachments: shareAttachments,
                },
                {
                    ...singleDoc,
                    id: documentId,
                    share_attachments: shareAttachments,
                }
            )
            setShowGlobalResponseLoader(false)

        }
        setShareAttachments(shareAttachments)
    }

    // On version restore
    const handleVersionRestore = (version) => {
        setDocumentValues(version.values)
        setInitialDocumentState(version.values)
        setSingleDocName(version.name)
        setCurrentDocVersionTime({ time: version.created })
    }

    // On agency modal open
    const handleOpenAgencyModal = () => {
        setShowAgencyModal(true)
    }

    // On agency modal close
    const handleCloseAgencyModal = () => {
        setShowAgencyModal(false)
    }

    // On notaries modal open
    const handleOpenNotariesModal = () => {
        setShowNotariesModal(true)
    }

    // On close notaries modal
    const handleCloseNotariesModal = () => {
        setShowNotariesModal(false)
    }

    // On open add/edit notary modal
    const handleOpenAddEditNotaryModal = () => {
        setShowAddEditNotaryModal(true)
    }

    // On close add/edit notary modal
    const handleCloseAddEditNotaryModal = () => {
        setShowAddEditNotaryModal(false)
    }

    const onPreviewSettingsChange = (settings) => {
        setPreviewSettings(settings)
    }

    const handleToggleMoreActions = () => {
        setShowMoreActions(!showMoreActions)
    }

    const handleScroll = () => {
        const element = document.getElementById('direction1')
        const { scrollTop } = element

        if (arrayStack[arrayStack.length - 1] < scrollTop) {
            arrayStack.push(scrollTop)
            setIsScroll(true)
        } else {
            arrayStack.length = 0
            arrayStack.push(scrollTop)
            setIsScroll(false)
        }
    }

    const handleRenderSettingsSave = (settings) => {
        console.log('handleRenderSettingsSave', settings)
        setUseCustomCover(settings.useCustomCover ?? singleDoc.custom_cover ?? agency.cover_page_default ?? true)
        setPreviewSettings({
            size: settings.size || 'normal',
            watermark: settings.watermark || false
        })
        setLinesAdded(settings.linesAdded || false)
    }

    if (templateNotFound) {
        return (
            <MainLayout
                className="page-document"
                pageTitle="Mes documents"
                titleIcon={<FileAlt2 />}
            >
                <ResourceNotFound
                    title="Modèle non trouvé"
                    text="Le modèle de ce document est introuvable"
                />
            </MainLayout>
        )
    }

    if (!singleDocReady) {
        return (
            <MainLayout
                className="page-document"
                pageTitle="Mes documents"
                titleIcon={<FileAlt2 />}
            >
                <ResponseLoader
                    text="Document en cours de création"
                    className="response-loader-v2--dark"
                />
            </MainLayout>
        )
    }

    if(singleDocDeleted) {
        return (
            <MainLayout
                className="page-document"
                pageTitle="Mes documents"
                titleIcon={<FileAlt2 />}
            >
                <ResourceNotFound
                    title="Document à supprimer"
                    text={"Pour visualiser le document, veuillez le sélectionner puis cliquer sur \"Restaurer\". Il sera alors de nouveau visible dans vos documents actifs."}
                />
            </MainLayout>
        )
    }

    if(singleDocArchived) {
        return (
            <MainLayout
                className="page-document"
                pageTitle="Mes documents"
                titleIcon={<FileAlt2 />}
            >
                <ResourceNotFound
                    title="Document archivé"
                    text={"Pour visualiser le document, veuillez le sélectionner puis cliquer sur \"Restaurer\". Il sera alors de nouveau visible dans vos documents actifs."}
                />
            </MainLayout>
        )
    }

    if (singleDocNotFound) {
        return (
            <MainLayout
                className="page-document"
                pageTitle="Mes documents"
                titleIcon={<FileAlt2 />}
            >
                <ResourceNotFound
                    title="Document non trouvé"
                    text={`Le document avec l'id "${params.id}" est introuvable`}
                />
            </MainLayout>
        )
    }

    return (
        <MainLayout
            className="page-document"
            pageTitle="Rédaction d’un document"
            hideTitleOnDesktop={true}
            showSearch={true}
            searchIn="documentItem"
        >
            <Prompt
                when={isBlocking}
                message={() =>
                    `Les modifications que vous avez apportées ne seront pas enregistrées. Souhaitez-vous quitter cette page?`
                }
            />
            <div
                className=""
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                }}
            >
                <div className="single-document-mobile-title">
                    <h1
                        style={{ width: '80%' }}
                        contentEditable
                        suppressContentEditableWarning={true}
                        onInput={handleNameChange}
                    >
                        {singleDoc.name}
                    </h1>
                    {/* <div style={{ position: 'relative' }}>
                        {showMoreActions && (
                            <div
                                className="more-dropdown"
                                style={{
                                    position: 'absolute',
                                    right: 10,
                                    top: 15,
                                    width: '200px',
                                    padding: '5px',
                                }}
                            >
                                <ul>
                                    <li
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                        onClick={() => {
                                            setHideActionBar(
                                                (prev) => !prev
                                            )
                                            setTimeout(() => {
                                                handleToggleMoreActions()
                                            }, 150)
                                        }}
                                    >
                                        {hideActionBar ? (
                                            <VisibilityIcon />
                                        ) : (
                                            <VisibilityOffIcon />
                                        )}
                                        <span
                                            className="text"
                                            style={{ marginLeft: '6px' }}
                                        >
                                            hide/show action panel
                                        </span>
                                    </li>
                                </ul>
                            </div>
                        )}
                    </div> */}
                </div>
            </div>
            <div
                className={`single-document ${sidePanelOpenStatus[view] ? 'opened' : ''
                    }`}
            >
                <SingleDocumentSidebar
                    singleDoc={singleDoc}
                    currentDocVersionTime={currentDocVersionTime}
                    documentValues={documentValues}
                    onVariableItemClick={handleVariableItemClick}
                    scrollingToItem={scrollingToItem}
                    onSectionClick={handleSectionClick}
                    templateData={templateObjectWithUniqueVarIndexes}
                    tabActive={activeTab === 'summary'}
                    onNameChange={handleNameChange}
                    singleDocName={singleDocName}
                    view={view}
                    onSetView={view => {
                        setView(view)
                    }}
                    numOfAttachments={[...filteredDocumentAttachments, ...filteredDefaultAttachments].length}
                    attachments={[...filteredDocumentAttachments, ...filteredDefaultAttachments]}
                    status={singleDocStatus}
                    onSetStatus={setSingleDocStatus}
                    progress={progress}
                    templateMetaSections={templateMetaSections}
                    summarySections={summarySections}
                />
                <div
                    className={`single-document__main document-main single-document-tab ${activeTab === 'main' ? 'tab-active' : ''
                        } u-custom-scrollbar`}
                    id="direction1"
                    ref={bodyWrapEl}
                    onScroll={() => handleScroll()}
                >
                    <SingleDocumentMain
                        singleDoc={{
                            ...singleDoc,
                            id: documentId,
                            name: singleDocName,
                            status: singleDocStatus,
                            attachments: documentAttachments,
                            values: documentValues,
                            checkboxValues: documentCheckboxValues,
                        }}
                        templateData={templateObjectWithUniqueVarIndexes}
                        documentValues={documentValues}
                        onValuesChange={handleDocumentValuesChange}
                        documentCheckboxValues={documentCheckboxValues}
                        onCheckboxValueChange={
                            handleDocumentCheckboxValuesChange
                        }
                        useCustomCover={useCustomCover}
                        onAgencyModalOpen={handleOpenAgencyModal}
                        onNotariesModalOpen={handleOpenNotariesModal}
                        onSave={handleSave}
                        onDocumentPreview={handleNavbarDocumentPreview}
                        linesAdded={linesAdded}
                        attachments={documentAttachments}
                        // onAttachmentShareUpdate={
                        //     handleAttachmentShareUpdate
                        // }
                        onAttachmentShareSave={handleAttachmentShareSave}
                        documentId={documentId}
                        documentName={singleDocName}
                        template={templateObject}
                        docContacts={documentContacts}
                        activeDocContacts={activeDocumentContacts}
                        docSignatureRecipients={docSignatureRecipients}
                        onSetDocumentValues={setDocumentValues}
                        onFetchHistory={handleFetchDocumentHistory}
                        docHistory={singleDocHistory}
                        docEvents={singleDocEvents}
                        onVersionRestore={handleVersionRestore}
                        onResetSetup={resetSetup}
                        view={view}
                        onSetView={setView}
                        onSetDocumentAttachments={setDocumentAttachments}
                        onSetTab={handleTabChange}
                        onToggleSidePanel={handleToggleSidePanel}
                        isSidePanelOpen={sidePanelOpenStatus[view]}
                        isContentEditable={isContentEditable}
                        onToggleContentEditable={handleToggleContentEditable}
                        onSectionContentChanged={onSectionContentChanged}
                        onDocumentDownload={handleDocumentDownload}
                        previewSettings={previewSettings}
                        templateMetaSections={templateMetaSections}
                        onPreviewRefresh={() => handleDocumentPreview(singleDoc, documentValues, templateObject)}
                        onRenderSettingsSave={handleRenderSettingsSave}
                        progress={progress}
                        onCreateStandardTemplate={handleCreateStandardTemplate}
                        onNavigateToEmptyVariable={handleNavigateToEmptyVariable}
                        renderOptions={renderOptions(singleDoc, templateObject)}
                        shareAttachments={shareAttachments}
                    />
                </div>
                <SingleDocumentSidePanel
                    hidePreviewInMobile={(e) => handleTabChange(e, 'summary')}
                    onToggle={() => {
                        setPrefilledAttachmentVariable(null)
                        handleToggleSidePanel()
                    }}
                    previewData={pdfPreviewData}
                    previewLoading={previewLoading}
                    onPreviewRefresh={() => handleDocumentPreview(singleDoc, documentValues, templateObject)}
                    tabActive={activeTab === 'preview'}
                    view={view}
                    onSetDocumentAttachments={setDocumentAttachments}
                    attachments={documentAttachments}
                    docId={documentId}
                    previewSettings={previewSettings}
                    onPreviewSettingsChange={onPreviewSettingsChange}
                />
            </div>

            {/* <div
                className="single-document-mobile-nav"
                style={{
                    position: !isScroll ? 'fixed' : 'absolute',
                    bottom: !isScroll ? '63px' : '0px',
                }}
            >
                <a
                    href="/"
                    className={activeTab === 'summary' ? 'active' : ''}
                    onClick={(e) => handleTabChange(e, 'summary')}
                >
                    <List width={'20px'} height={'20px'} />
                </a>
                <a
                    href="/"
                    className={
                        activeTab === 'main' && view === 'variables'
                            ? 'active'
                            : ''
                    }
                    onClick={(e) => handleTabChange(e, 'main')}
                >
                    <Pen width={'20px'} height={'20px'} />
                </a>
                <a
                    href="/"
                    className={
                        activeTab === 'preview' && view === 'variables'
                            ? 'active'
                            : ''
                    }
                    onClick={(e) => handleTabChange(e, 'preview')}
                >
                    <Eye2 width={'20px'} height={'20px'} />
                </a>
                <a
                    href="/"
                    className={
                        (activeTab === 'main' || activeTab === 'preview') &&
                            view === 'attachments'
                            ? 'active'
                            : ''
                    }
                    onClick={(e) => handleTabChange(e, 'main', true)}
                >
                    <Paperclip width={'20px'} height={'20px'} />
                </a>
            </div> */}

            {showAgencyModal && (
                <AgencyModal
                    onClose={handleCloseAgencyModal}
                    onSetDocValues={setDocumentValues}
                    token={token}
                />
            )}

            {showNotariesModal && (
                <NotariesModal
                    onClose={handleCloseNotariesModal}
                    onOpenAddEditNotaryModal={handleOpenAddEditNotaryModal}
                    onSetSelectedNotary={setSelectedNotary}
                />
            )}

            {showAddEditNotaryModal && (
                <AddEditNotaryModal
                    onClose={handleCloseAddEditNotaryModal}
                    mode={notaryMode}
                    notary={selectedNotary}
                />
            )}
            {showEditionWarningModal && (
                <Alert
                    text="Note importante"
                    bodyText="Vous vous apprêtez à passer en mode édition. Immo Docs ne pourra être tenu pour responsable des modifications apportées au document."
                    okBtnText="J'ai compris"
                    showOk={true}
                    onClose={() => setShowEditionWarningModal(false)}
                />
            )}
            {showOutdatedContentChangesModal && (
                <Alert
                    text="Note imporante"
                    bodyText={`La trame du document a été mise à jour par un administrateur. Les modifications apportées via le mode Édition sont malheureusement perdues.\n\nVeuillez nous excuser pour la gêne occasionnée.`}
                    okBtnText='Ok'
                    showOk={true}
                    onOkClose={() => {
                        setExistingContentChanges([])
                        setPartialContentChanges([])
                        setShowOutdatedContentChangesModal(false)
                    }}
                />
            )}
        </MainLayout>
    )
}

export default SingleDocument
