import { memo } from 'react'
import moment from 'moment'

import { dateValueFormat } from '../../utils'
import {  EMPTY_LINES } from '../../constants'
import { DateInput } from '../ui_new'

const VariableDateInput = ({fieldVariable, handle, val, repeatableIds, repetitionIndices, linesAdded, isHandleLocked, onInputChange}) => {
  const { variable, tooltip, idx} = fieldVariable

  // valid date format DD/MM/YYYY
  // check and convert from YYYY-MM-DD
  if (val && val.length === 10 && val.match(/^\d{4}-\d{2}-\d{2}$/)) {
    val = moment(val, 'YYYY-MM-DD').format(dateValueFormat)
  }
  // check and convert from YYYY/MM/DD
  else if (val && val.length === 10 && val.match(/^\d{4}\/\d{2}\/\d{2}$/)) {
    val = moment(val, 'YYYY/MM/DD').format(dateValueFormat)
  }
  // check and convert from YYYYMMDD
  else if (val && val.length === 8 && val.match(/^\d{8}$/)) {
    val = moment(val, 'YYYYMMDD').format(dateValueFormat)
  }

  const disabled = isHandleLocked(handle)
  
  return (
    <div className="tooltip-wrapper doc-variable-el" data-id={idx}>
      <div className={`${val ? 'date_picker-v2 not-empty' : 'date_picker-v2'}${disabled ? ' disabled' : ''}`}>
        <DateInput 
          selected={!val ? null : new Date(moment(val, dateValueFormat).valueOf())}
          onChange={date => date ? onInputChange(variable, moment(date.valueOf()).format(dateValueFormat), repeatableIds, repetitionIndices) : ''}
          peekNextMonth={true}
          showMonthDropdown={true}
          showYearDropdown={true}
          dropdownMode="select"
          disabled={disabled}
          tooltip={tooltip}
          isClearable={val}
          onClearableClick={() => onInputChange(variable, '', repeatableIds, repetitionIndices)}
          placeholder={linesAdded ? EMPTY_LINES.date : ''}
        />
      </div>
    </div>
  )
}

export default memo(VariableDateInput)