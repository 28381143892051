import moment from 'moment'

import { FileAlt, Paperclip, Pen, Draft } from '../../assets/icons'
import { Select, Option, CustomTooltip } from '../ui_new'
import { STATUS_LABELS_SHORT } from '../../constants'
import { SingleDocumentSummary } from '.'
import { useSingleDocumentActions } from '../../hooks'
import useWindowDimensions from '../../hooks/useWindowDimensions'
import { useState } from 'react'
import { AttachFile, CalendarTodayOutlined, ChevronLeft, Create } from '@mui/icons-material'

const StandardTemplateSidebar = ({
    singleDoc,
    currentDocVersionTime,
    documentValues,
    onVariableItemClick,
    scrollingToItem,
    onSectionClick,
    templateData,
    tabActive,
    onNameChange,
    view,
    onSetView,
    numOfAttachments,
    attachments,
    progress,
    templateMetaSections,
    summarySections,
}) => {
    const { extractVariableTypesFromSections } = useSingleDocumentActions()
    const [showInformationAccordion, setShowInformationAccordion] = useState(false);
    const [showSearchAccordion, setShowSearchAccordion] = useState(false);

    const { isMobile } = useWindowDimensions()

    return (
        <div
            className={`single-document__left single-document-tab`}
        >
            <section className="head">
                <h1>
                    <span>Mes modèles types</span>
                </h1>
            </section>
            <div className='accordion-parent'>
                <div className={`accordion-heading ${showInformationAccordion && 'active'}`} onClick={() => setShowInformationAccordion(!showInformationAccordion)}>
                    <span>Informations du document</span>
                    <ChevronLeft />
                </div>
                <section className={`${showInformationAccordion && 'active'} doc-info mb-6`}>
                    <label class="label-title color-indigo mb-1 pl-2">Titre du document</label>
                    <h2
                        contentEditable={true}
                        suppressContentEditableWarning={true}
                        onInput={onNameChange}
                    >
                        {singleDoc.name}
                        <span>
                            <Create fontSize='small' />
                        </span>
                    </h2>
                    <div className='px-2 mt-2'>
                        
                        <div className="status-row">
                            <label class="label-title mb-1 pl-1">
                                <span className='status-dot'></span>
                                Progress
                            </label>
                            <p className="doc-progress"><span>{`${(progress * 100).toFixed(0)}%`}</span></p>
                        </div>

                        {/* <p className="doc-status"><span className={`${singleDoc.status}`}></span> {STATUS_LABELS_SHORT[singleDoc.status]}</p> */}

                        {view === 'variables' && <div className="doc-version">
                            <div className="flex gap-2 align-center mb-1">
                                <CalendarTodayOutlined />
                                <label class="label-title">
                                    Version du document
                                </label>
                            </div>
                            <p>
                                {currentDocVersionTime &&
                                    currentDocVersionTime.time &&
                                    moment(currentDocVersionTime.time).format(
                                        'DD/MM/YYYY HH:mm'
                                    )}
                            </p>
                        </div>}
                    </div>
                    {view === 'variables' && <div className="doc-attachment-parent">
                        <div className="doc-attachment" >
                            <AttachFile />
                            <span className='leading-none'>Annexe(s) du document </span>
                            <span className="count">{numOfAttachments}/20</span>
                        </div>
                        <ul className="attachment-list">
                            {attachments?.map((attachment, idx) =>
                                <li>
                                    <Draft /> <span className='file-name'>{String(attachment.name)}</span>
                                </li>
                            )}
                        </ul>
                        <button class="button button--outline-primary-light rounded-sm btn-text w-full modify-btn" onClick={() => onSetView('attachments')}>
                            <Pen />
                            <span className="whitespace-nowrap">Modifier les annexes </span>
                        </button>
                    </div>}
                    {view === 'attachments' && <button class="btn" onClick={() => onSetView('variables')}>Retour au document</button>}
                </section>
            </div>

            <div className='accordion-parent'>
                <div className={`accordion-heading ${showSearchAccordion && 'active'}`} onClick={() => setShowSearchAccordion(!showSearchAccordion)}>
                    <span>Sommaire</span>
                    <ChevronLeft />
                </div>

                <section className={`${showSearchAccordion && 'active'} summary`}>
                    <h3>Sommaire</h3>
                    <SingleDocumentSummary
                        documentValues={documentValues}
                        variableTypes={extractVariableTypesFromSections()}
                        onItemClick={onVariableItemClick}
                        scrollingToItem={scrollingToItem}
                        onSectionClick={onSectionClick}
                        templateData={templateData}
                        templateMetaSections={templateMetaSections}
                        summarySections={summarySections}
                    />
                </section>
            </div>
        </div>
    )
}

export default StandardTemplateSidebar
