import React, { createContext, useReducer } from 'react';

import tagsReducer from './tagsReducer';
import { fetch_tags, create_tag, update_tag, delete_tag, fetch_tag_matchings } from '../../services/firestore';
import { SET_TAGS, SET_TAG_MATCHINGS } from '../types';

export const TagsContext = createContext();

const TagsState = ({ children }) => {
  const initialState = {
    tags: [],
    tagsFetched: false,
    tagMatchings: [],
    tagMatchingsFetched: false
  }

  const [state, dispatch] = useReducer(tagsReducer, initialState);

  const fetchTagMatchings = async () => {
    try {
      const res = await fetch_tag_matchings();
      const arr = [];
      for(let id in res) {
        const tagMatching = {...res[id]};
        tagMatching.id = id;
        arr.push(tagMatching);
      }
      dispatch({
        type: SET_TAG_MATCHINGS,
        payload: arr
      });
    } catch (err) {
      console.log(err)
    }
  }

  const fetchTags = async () => {
    try {
      const res = await fetch_tags();
      const arr = [];
      for(let id in res) {
        const tag = {...res[id]};
        tag.id = id;
        arr.push(tag);
      }
      dispatch({
        type: SET_TAGS,
        payload: arr
      });
    } catch (err) {
      console.log(err)
    }
  }

  // Create tag
  const createTag = async (data, onSuccess = () => {}, onError = () => {}) => {
    try {
      const res = await create_tag(data);
      const tag = {...res.data};
      const tagsArr = [...state.tags];
      tagsArr.unshift(tag);
      dispatch({
        type: SET_TAGS,
        payload: tagsArr
      });
      onSuccess();
    } catch (err) {
      console.log(err);
      onError(err);
    }
  }

  // Update tag
  const updateTag = async (id, data, onSuccess = () => {}, onError = () => {}) => {
    try {
      await update_tag(id, data);
      const tags = [...state.tags];
      const findS = tags.find(s => s.id === id);
      const updatedS = {...findS, ...data};
      const updatedTags = tags.map(s => s.id === id ? updatedS : s);

      dispatch({
        type: SET_TAGS,
        payload: updatedTags
      });
      onSuccess(updatedS);
    } catch (err) {
      console.log(err);
      onError(err);
    }
  }

  // Delete tag
  const deleteTag = async (id, onSuccess = () => {}, onError = () => {}) => {
    try {
      await delete_tag(id);
      const tags = [...state.tags];
      const filtered = tags.filter(s => s.id !== id);
      dispatch({
        type: SET_TAGS,
        payload: filtered
      });
      onSuccess();
    } catch (err) {
      console.log(err);
      onError(err);
    }
  }

  return <TagsContext.Provider value={{
    tags: state.tags,
    tagsFetched: state.tagsFetched,
    tagMatchings: state.tagMatchings,
    tagMatchingsFetched: state.tagMatchingsFetched,
    fetchTags,
    createTag,
    updateTag,
    deleteTag,
    fetchTagMatchings,
  }}>
    {children}
  </TagsContext.Provider>
}

export default TagsState;